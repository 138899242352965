import { Card, Icon, ImageWithErrorFallback, Text } from "app/components";
import { DealroomNews } from "@/gql/graphql.ts";

import { format } from "date-fns";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { EmptySection } from "@/app/screens/opportunities/opportunity/components/empty-section.tsx";
import React from "react";
import { Link } from "react-router-dom";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import { cn } from "@/lib/utils.ts";

type NewsProps = {
  news?: DealroomNews[];
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const News = ({ news = [] }: NewsProps) => {
  const { viewMode } = useOpportunityContext();

  if (!news.length) {
    return <EmptySection text={"No News Found"} />;
  }

  return (
    <div className="relative inline-flex w-full flex-nowrap">
      <Carousel
        responsive={responsive}
        className="!static w-full"
        customLeftArrow={
          <button className="absolute left-0 -translate-x-1/3 cursor-pointer select-none rounded-full border-2 border-white bg-neutral-100 p-2.5 md:p-4 hover:bg-neutral-200 active:shadow-inner">
            <Icon className="h-5 w-5" type="Chevron Left" />
          </button>
        }
        customRightArrow={
          <button className="absolute right-0 translate-x-1/3 cursor-pointer select-none rounded-full border-2 border-white bg-neutral-100 p-2.5 md:p-4 hover:bg-neutral-200 active:shadow-inner">
            <Icon className="h-5 w-5" type="Chevron Right" />
          </button>
        }
      >
        {news.map((item) => (
          <Card key={item.title} className={"group relative flex h-full flex-col p-0 overflow-hidden md:mr-2"}>
            <div className="flex-0 h-[200px] w-full overflow-hidden">
              <ImageWithErrorFallback
                className={cn(
                  "flex h-full w-full items-center justify-center bg-neutral-200 object-cover",
                  viewMode === "contrast" && "bg-neutral-100",
                )}
                iconClassName={cn(
                  "w-12 h-12 bg-neutral-200 opacity-10",
                  viewMode === "contrast" && "bg-neutral-100 opacity-30",
                )}
                src={item.image}
                alt={"News Image"}
              />
            </div>
            <div className="flex flex-1 flex-col overflow-hidden p-6 pl-8 pt-4">
              <Text className="line-clamp-2" type="subtitle" text={item.title ?? ""} />
              <Text
                className="mt-3 line-clamp-5 flex-1 overflow-hidden font-barlow"
                weight="light"
                text={item.summary ?? ""}
              />
              <div className="mt-4 flex justify-between">
                {/*todo avatar*/}
                {item.author?.name && <Text className="text-gray-800" text={item.author.name} />}
                <Text
                  className="ml-auto text-gray-500"
                  weight="light"
                  text={format(new Date(item.pubDate), "LLL dd, YYY")}
                />
              </div>
            </div>
            <div className="opacity-0 transition-opacity group-hover:opacity-100">
              <div className="absolute top-0 h-full w-full bg-black opacity-15"></div>
              {item.link != null && (
                <Link
                  className="absolute right-6 top-6 rounded-sm bg-neutral-700/60 p-2 hover:bg-neutral-700/70"
                  to={item.link}
                  target={"_blank"}
                >
                  <Icon className="h-6 w-6 text-white" type={"ExternalLink"} />
                </Link>
              )}
            </div>
          </Card>
        ))}
      </Carousel>
    </div>
  );
};

import React, { useState } from "react";

import { Card, IconButton, ImageWithErrorProfile, Text } from "@/app/components";
import { authStore } from "@/app/stores/auth.store.tsx";

import { CommentDisplayData, isTrelloComment } from "../comments.types.ts";

import { CommentActions } from "./comment-actions.tsx";
import { useBreakpoints } from "@/lib/hooks";
import { CommentBody } from "./comment-body.tsx";
import { CommentDate, EditedDate } from "./comment-date.tsx";
import { EditCommentInput } from "./edit-comment-input.tsx";
import modalStore from "@/app/stores/modal.store.tsx";
import { CommentActionsMobile } from "@/app/screens/modal";

const CommentHeader = ({
  comment,
  onEdit,
  mode,
}: {
  comment: CommentDisplayData;
  onEdit: () => void;
  mode: "view" | "edit";
}) => {
  // only author can edit the comment
  const canEdit = !isTrelloComment(comment) && comment.author?.id === authStore.user?.id && mode === "view";
  const { isLaptop } = useBreakpoints();

  return (
    <div className="mb-4 flex items-center justify-between">
      <div className="flex flex-row items-center bg-white">
        <ImageWithErrorProfile
          src={comment.author?.avatar}
          alt={comment.author?.initials ?? ""}
          className={"mr-4 size-12 rounded-sm bg-neutral-200"}
          labelClassName={"text-center"}
        />
        <Text weight={"bold"}>{comment.author?.fullName}</Text>

        <CommentDate date={comment.date} />
        {comment.dateLastEdited > comment.date && <EditedDate date={comment.dateLastEdited} />}
      </div>
      {canEdit &&
        (isLaptop ? (
          <CommentActions comment={comment} setMode={onEdit} />
        ) : (
          <IconButton
            icon="MoreVertical"
            className="size-4"
            onClick={() =>
              modalStore.open(CommentActionsMobile, {
                props: {
                  comment,
                  setMode: onEdit,
                },
              })
            }
          />
        ))}

      {mode === "edit" && <div className="select-none text-sm text-gray-400">Edit comment</div>}
    </div>
  );
};

export const CommentItem = ({ comment }: { comment: CommentDisplayData }) => {
  const [mode, setMode] = useState<"view" | "edit">("view");

  const handleCancel = () => {
    setMode("view");
  };

  return (
    <Card>
      <CommentHeader comment={comment} onEdit={() => setMode("edit")} mode={mode} />
      <div>
        {mode === "edit" ? (
          <EditCommentInput comment={comment} onClose={handleCancel} />
        ) : (
          <div>
            <CommentBody comment={comment} />
          </div>
        )}
      </div>
    </Card>
  );
};

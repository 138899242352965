export type EmailProps = {
  subject: string;
  from?: string | null;
  to?: string | null;
  source?: string | null;
  body: string;
};

export const EmailView = ({ email }: { email: EmailProps }) => {
  return (
    <div className="max-h-[420px]">
      Subject: {email.subject}
      {!!email.from && (
        <>
          <br />
          From: {email.from}
        </>
      )}
      {!!email.to && (
        <>
          <br />
          To: {email.to}
        </>
      )}
      <br />
      Source: {email.source}
      <br />
      <br />
      <pre className="whitespace-pre-wrap">{email.body}</pre>
    </div>
  );
};

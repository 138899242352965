import ReactPaginate from "react-paginate";

import { cn } from "@/lib/utils.ts";

import { Icon } from "@/app/components";
import { TableDef, TableRowData } from "@/app/components/table-new";
import { useTable } from "@/app/components/table-new/table.hooks.ts";

const isPaginationEnabled = (table: TableDef<TableRowData>): boolean => {
  const manualPagination = table.options.manualPagination;
  if (manualPagination) {
    return true;
  }

  const isPaginationEnabled = table.options.getPaginationRowModel !== undefined;

  if (!isPaginationEnabled) {
    return false;
  }

  const totalPages = table.getPageCount();

  return totalPages > 1;
};

export const Pagination = () => {
  const { table } = useTable();

  if (!table) {
    return null;
  }

  const isEnabled = isPaginationEnabled(table);

  if (!isEnabled) {
    return null;
  }

  const totalPages = table.getPageCount();

  return (
    <ReactPaginate
      pageLinkClassName={"hover:text-neutral-400"}
      className={cn(
        "bottom-1 mt-4 flex h-10 w-full flex-1 items-center justify-center gap-x-5 text-neutral-800 hover:text-neutral-800 sticky top-0 left-0 right-0 bg-white z-10",
      )}
      activeClassName={
        "text-neutral-800 bg-neutral-100 px-2.5 py-0.5 rounded-xs transition-all duration-50 ease-linear"
      }
      containerClassName={"relative"}
      onPageChange={({ selected }) => table.setPageIndex(selected)}
      pageRangeDisplayed={2}
      pageCount={totalPages}
      marginPagesDisplayed={2}
      breakLabel="..."
      nextLabel={<Icon className="size-4" type={"Chevron Right"} />}
      nextClassName={
        "bg-neutral-100 hover:bg-neutral-200 text-neutral-700 p-2 rounded-xs font-semibold pointer-select select-none right-6 absolute"
      }
      previousLabel={<Icon className="size-4" type={"Chevron Left"} />}
      previousClassName={
        "bg-neutral-100 hover:bg-neutral-200 text-neutral-700 p-2 rounded-xs font-semibold pointer-select select-none left-6 absolute"
      }
      renderOnZeroPageCount={null}
    />
  );
};

import React from "react";

import { observer } from "mobx-react";

import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";

import { CardList } from "./card-list";
import { TableList } from "./table-list/table";

export const WebOpportunitiesList = observer(function WebOpportunitiesList({ allOpportunities }) {
  const { listType, currentTab } = opportunitiesStore.state;

  if (!allOpportunities?.length) {
    return null;
  }

  const opportunities = allOpportunities.map(({ node }) => node);

  return (
    <div id={"web_opportunities_list"} className="w-full pt-1.5 @container">
      {listType === "card" ? (
        <CardList opportunities={opportunities} currentTab={currentTab.path} className="" />
      ) : (
        <div className="size-full">
          <TableList opportunities={opportunities} />
        </div>
      )}
    </div>
  );
});

import { GetCompanyUpdatesDocument, GetCompanyUpdatesQuery } from "@/gql/graphql.ts";

import { usePaginatedQuery } from "@/app/hooks";
import { DashboardSectionData, NotificationStatus } from "@/app/screens/dashboard/dashboard.types.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

export const newItemStatuses = [NotificationStatus.UNSEEN, NotificationStatus.SEEN, NotificationStatus.LOADING];
export const completedStatuses = [NotificationStatus.COMPLETED, NotificationStatus.DECLINED];

type CompanyNotificationData = NonNullable<GetCompanyUpdatesQuery["nzrNotifications"]>["edges"][number]["node"];

export const useCompanyUpdates = (showArchived: boolean): DashboardSectionData<CompanyNotificationData> => {
  const queryVariables = {
    assignee: authStore.user?.id,
    pageSize: 6,
    statuses: showArchived ? completedStatuses : newItemStatuses,
  };

  const {
    data,
    loading,
    loadingLess,
    loadingMore,
    loadLess,
    loadMore: fetchMore,
  } = usePaginatedQuery({
    query: GetCompanyUpdatesDocument,
    variables: queryVariables,
    rootEntity: "nzrNotifications",
    options: { fetchPolicy: "network-only", pollInterval: 10_000 },
  });

  const loadMore = () => {
    const endCursor = data?.nzrNotifications?.pageInfo.endCursor;
    if (fetchMore) {
      fetchMore(queryVariables, endCursor);
    }
  };

  if (!data?.nzrNotifications) {
    return {
      totalCount: 0,
      items: [],
      loading,
      loadingLess: false,
      loadingMore: false,
      pageInfo: null,
    };
  }

  return {
    totalCount: data.nzrNotifications.totalCount,
    items: data.nzrNotifications.edges.map(({ node }) => node),
    pageInfo: data.nzrNotifications.pageInfo,
    loading: loading && !loadingLess,
    loadingLess,
    loadingMore,
    loadMore,
    loadLess,
  };
};

import { GetForwardedCompaniesDocument } from "@/gql/graphql";

import { usePaginatedQuery } from "@/app/hooks";
import { DashboardSectionData, NotificationStatus } from "@/app/screens/dashboard/dashboard.types.ts";
import { GetForwardedCompaniesQuery } from "@/app/service/dashboard.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

export const newItemStatuses = [NotificationStatus.UNSEEN, NotificationStatus.SEEN];
const completedStatuses = [NotificationStatus.COMPLETED, NotificationStatus.DECLINED];

type ForwardedCompanyItemData = NonNullable<GetForwardedCompaniesQuery["nzrNotifications"]>["edges"][number]["node"];

export const useCompaniesForwarded = (showArchived: boolean): DashboardSectionData<ForwardedCompanyItemData> => {
  const queryVariables = {
    assignee: authStore.user?.id,
    pageSize: 3,
    statuses: showArchived ? completedStatuses : newItemStatuses,
  };

  const {
    data,
    loading,
    loadingLess,
    loadingMore,
    loadLess,
    loadMore: fetchMore,
  } = usePaginatedQuery({
    query: GetForwardedCompaniesDocument,
    rootEntity: "nzrNotifications",
    variables: queryVariables,
    options: { fetchPolicy: "cache-and-network" },
  });

  const loadMore = () => {
    const endCursor = data?.nzrNotifications?.pageInfo.endCursor;

    if (fetchMore) {
      fetchMore(queryVariables, endCursor);
    }
  };

  if (!data?.nzrNotifications) {
    return {
      totalCount: 0,
      items: [],
      loading,
      loadingLess: false,
      loadingMore: false,
      pageInfo: null,
    };
  }

  return {
    totalCount: data.nzrNotifications.totalCount,
    items: data.nzrNotifications.edges.map(({ node }) => node),
    pageInfo: data.nzrNotifications.pageInfo,
    loading: loading && !loadingLess,
    loadingLess,
    loadingMore,
    loadMore,
    loadLess,
  };
};

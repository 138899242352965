import { ProfileImage } from "@/app/components";
import { authStore } from "@/app/stores/auth.store.tsx";

import { AgendaReminder } from "./agenda-reminder";

export const DashboardHeader = () => {
  const currentUser = authStore.user;

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <div data-cy="dashboard_header" className="mb-9 mt-10 flex items-center gap-4 lg:mt-0">
        <ProfileImage
          containerClass="size-16 rounded-md text-3xl"
          image={currentUser.avatar}
          firstname={currentUser.firstName ?? ""}
          lastname={currentUser.lastName ?? ""}
        />
        <div>
          <h1 className="text-3xl">
            Welcome, {currentUser?.firstName}!<span className="ml-1">👋</span>
          </h1>
          <h3 className="mt-1 text-neutral-400">Here are your latest tasks</h3>
        </div>
      </div>
      <AgendaReminder />
    </>
  );
};

import { useCallback, useMemo, useState } from "react";
import { Combobox } from "@headlessui/react";
import { observer } from "mobx-react";
import { Button, Loader, Modal, SearchBar, Text } from "@/app/components";
import { START_QUERY_AFTER, useSearch } from "@/app/hooks";
import { useNavigate } from "react-router";
import { SearchResults } from "./components";
import modalStore from "@/app/stores/modal.store";
import { AddCompanyNewModal } from "@/app/screens/modal/actions/add-company-new/add-company-new-modal.tsx";

export const SearchMobile = observer(() => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const { search, reset, queryRef, loading } = useSearch();

  const handleClose = useCallback(() => {
    setSearchTerm("");
    reset();
    modalStore.close();
  }, [reset]);

  const handleSearch = (query: string) => {
    const shouldQuery = query.length > START_QUERY_AFTER;
    setSearchTerm(query);
    if (shouldQuery) {
      search({ name: { fuzzy: query } });
    }

    if (!shouldQuery && queryRef) {
      reset();
    }
  };

  const goTo = useCallback(
    (path: string) => {
      navigate(`company/${path}`);
      handleClose();
    },
    [navigate, handleClose],
  );

  const addNew = useCallback(() => {
    handleClose();
    modalStore.navigate(AddCompanyNewModal, {
      props: {
        companyName: searchTerm,
      },
    });
  }, [handleClose]);

  const Content = useMemo(() => {
    if (queryRef) {
      return (
        <SearchResults
          queryRef={queryRef}
          searchTerm={searchTerm}
          handleSelect={({ id }) => goTo(id)}
          className="max-h-full max-w-full border-none pt-0"
          handleAddNewOpportunity={addNew}
        />
      );
    }

    if (!loading) {
      return (
        <section className="mt-[40%] text-center">
          <Text className="text-3xl" type="h1">
            Search companies
          </Text>
          <Text className="mt-2 text-base" weight="light" type="body">
            Start typing to find companies
          </Text>
        </section>
      );
    }

    return null;
  }, [queryRef, searchTerm, loading, goTo, addNew]);

  return (
    <Modal
      isOpen={true}
      handleClose={modalStore.close}
      bodyContainerClassName="bg-white"
      bodyClassName="h-full rounded-none bg-white"
      showX={false}
    >
      <div className="fixed inset-0 z-10 w-screen h-screen bg-white overflow-y-auto">
        <div className="flex items-center p-4">
          <SearchBar
            inputClassName="py-3 pr-4 pl-10 w-full text-sm rounded-sm caret-primary bg-gray-100"
            className="h-auto w-[75dvw] rounded-md border-none"
            closeIcon="X"
            onQueryChange={handleSearch}
            clearIcon
          />
          <Button
            text="Cancel"
            variant="text"
            className="min-w-[0px] text-base text-black active:bg-transparent"
            onClick={handleClose}
            data-cy="search-bar-close-mobile"
          />
        </div>
        <Loader className={`${loading ? "absolute z-10 top-[35%]" : "hidden"}`} />
        <Combobox>
          <div className="relative">{Content}</div>
        </Combobox>
      </div>
    </Modal>
  );
});

import {
  CalendarDays,
  ChevronDown,
  AlertTriangle,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Crosshair,
  DollarSign,
  Factory,
  Flag,
  Copy,
  PoundSterling,
  Globe,
  Check,
  Plus,
  Minus,
  Receipt,
  Mail,
  X,
  Users2,
  Rocket,
  BadgeInfo,
  StickyNote,
  ShieldAlert,
  Link,
  Unlink,
  User,
  MoveDownLeft,
  MoveUpLeft,
  MoveUpRight,
  MoveDownRight,
  DotIcon,
  Triangle,
  LucideProps,
  Edit,
  Pencil,
  ExternalLink,
  Table,
  Percent,
  LineChart,
  Users,
  MoreVertical,
  Speech,
  EyeOff,
  RefreshCcw,
  RefreshCw,
  Info as InfoOutline,
  List,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Pilcrow,
  ListOrdered,
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Code,
  AtSign,
  Quote,
  Command,
  Option,
  ArrowBigUp,
  Euro,
} from "lucide-react";
import { Loader2Icon } from "lucide-react";

import { cn } from "@/lib/utils.ts";

import { ColorType } from "@/app/components";
import { SearchPrimary } from "@/app/components/icon/jsx/search-primary.tsx";
import AddGradient from "@/app/components/icon/svg/add-gradient.svg?react";
import AddedByMe from "@/app/components/icon/svg/Added By Me.svg?react";
import ArrowLeft from "@/app/components/icon/svg/arrow-left.svg?react";
import ArrowRight from "@/app/components/icon/svg/arrow-right.svg?react";
import ArrowUpCircle from "@/app/components/icon/svg/arrow-up-circle.svg?react";
import AssignedTo from "@/app/components/icon/svg/assigned-to.svg?react";
import Attach from "@/app/components/icon/svg/attach.svg?react";
import Attachment from "@/app/components/icon/svg/attatchment.svg?react";
import BarsIncrease from "@/app/components/icon/svg/bars-increase.svg?react";
import BasecampFunds from "@/app/components/icon/svg/basecamp-funds.svg?react";
import Basecamp from "@/app/components/icon/svg/basecamp.svg?react";
import Bell from "@/app/components/icon/svg/bell.svg?react";
import Burger4 from "@/app/components/icon/svg/burger4.svg?react";
import BurgerMenu from "@/app/components/icon/svg/burgermenu.svg?react";
import Calendar from "@/app/components/icon/svg/calendar.svg?react";
import CardMenu from "@/app/components/icon/svg/cardmenu.svg?react";
import ChatBubble from "@/app/components/icon/svg/chat-bubble.svg?react";
import CHF from "@/app/components/icon/svg/chf.svg?react";
import Cities from "@/app/components/icon/svg/Cities.svg?react";
import CloseCircle from "@/app/components/icon/svg/close-circle.svg?react";
import CmdKey from "@/app/components/icon/svg/cmd-k.svg?react";
import Cmd from "@/app/components/icon/svg/cmd.svg?react";
import Comment from "@/app/components/icon/svg/comment.svg?react";
import CompanyImg from "@/app/components/icon/svg/company-404.svg?react";
import Company from "@/app/components/icon/svg/company.svg?react";
import Corn from "@/app/components/icon/svg/Corn.svg?react";
import Crown from "@/app/components/icon/svg/crown.svg?react";
import Crunchbase from "@/app/components/icon/svg/crunchbase.svg?react";
import Dealroom from "@/app/components/icon/svg/dealroom.svg?react";
import Decrease from "@/app/components/icon/svg/decrease.svg?react";
import Delete from "@/app/components/icon/svg/delete.svg?react";
import DOCIcon from "@/app/components/icon/svg/doc.svg?react";
import DoubleArrowLeft from "@/app/components/icon/svg/double-arrow-left.svg?react";
import DoubleArrowRight from "@/app/components/icon/svg/double-arrow-right.svg?react";
import DownUpSort from "@/app/components/icon/svg/down-up-sort.svg?react";
import DownUp from "@/app/components/icon/svg/down-up.svg?react";
// import Edit from "@/app/components/icon/svg/edit.svg?react";
import EMEA from "@/app/components/icon/svg/EMEA.svg?react";
import EUR from "@/app/components/icon/svg/EUR.svg?react";
import ExcitementExists from "@/app/components/icon/svg/excitement-exists.svg?react";
import Eye from "@/app/components/icon/svg/eye.svg?react";
import FillCheck from "@/app/components/icon/svg/fill-check.svg?react";
import FilterChevronDown from "@/app/components/icon/svg/filter-chevron-down.svg?react";
import FilterChevronUp from "@/app/components/icon/svg/filter-chevron-up.svg?react";
import Filter from "@/app/components/icon/svg/filter.svg?react";
import FirstDollarToFollow from "@/app/components/icon/svg/first-dollar-to-follow.svg?react";
import Founder from "@/app/components/icon/svg/founder.svg?react";
import Analysis from "@/app/components/icon/svg/fund-analysis.svg?react";
import GBP from "@/app/components/icon/svg/GBP.svg?react";
import GradientFounder from "@/app/components/icon/svg/gradient-founder.svg?react";
import GradientOpportunity from "@/app/components/icon/svg/gradient-opportunity.svg?react";
import GradientSentiment from "@/app/components/icon/svg/gradient-sentiment.svg?react";
import HandShake from "@/app/components/icon/svg/handshake.svg?react";
import ImageIcon from "@/app/components/icon/svg/image.svg?react";
import IncludeExists from "@/app/components/icon/svg/include-exists.svg?react";
import Increase from "@/app/components/icon/svg/increase.svg?react";
import Info from "@/app/components/icon/svg/info.svg?react";
import Insight from "@/app/components/icon/svg/insight.svg?react";
import InvestmentType from "@/app/components/icon/svg/investment-type.svg?react";
import LastRoundAmount from "@/app/components/icon/svg/last-round-ammount.svg?react";
import LastRoundAnounced from "@/app/components/icon/svg/last-round-anounced.svg?react";
import LastRoundLead from "@/app/components/icon/svg/last-round-lead.svg?react";
import LastRound from "@/app/components/icon/svg/last-round.svg?react";
import LastSentimentAdded from "@/app/components/icon/svg/last-sentiment-added.svg?react";
import LatestExcitementScores from "@/app/components/icon/svg/latest-excitement-score.svg?react";
import Latitude from "@/app/components/icon/svg/latitude.svg?react";
import LedBy from "@/app/components/icon/svg/led-by.svg?react";
import Linkedin from "@/app/components/icon/svg/linkedin.svg?react";
import LocalGlobe from "@/app/components/icon/svg/localglobe.svg?react";
import Lock from "@/app/components/icon/svg/lock.svg?react";
import LogOut from "@/app/components/icon/svg/log-out.svg?react";
import Logo from "@/app/components/icon/svg/logo.svg?react";
import LoopGradient from "@/app/components/icon/svg/loop-gradient.svg?react";
import LoopClouds from "@/app/components/icon/svg/loopClouds.svg?react";
import Maximise from "@/app/components/icon/svg/maximise.svg?react";
import MeetThePortfolioIcon from "@/app/components/icon/svg/meet-the-portfolio.svg?react";
import MenuCross from "@/app/components/icon/svg/menu-cross.svg?react";
import Minimise from "@/app/components/icon/svg/minimise.svg?react";
import NavAsPercent from "@/app/components/icon/svg/nav-as-percent.svg?react";
import NavInvested from "@/app/components/icon/svg/nav-invested.svg?react";
import Navigate from "@/app/components/icon/svg/navigate.svg?react";
import Network from "@/app/components/icon/svg/network.svg?react";
import NewPaloAlto from "@/app/components/icon/svg/new-palo-alto.svg?react";
import NoData from "@/app/components/icon/svg/no-data.svg?react";
import NoOfInvestors from "@/app/components/icon/svg/no-of-investors.svg?react";
import NoResults from "@/app/components/icon/svg/no-results.svg?react";
import NotificationNote from "@/app/components/icon/svg/notification-note.svg?react";
import OpportunityPlaceholder from "@/app/components/icon/svg/opportunity-placeholder.svg?react";
import Opportunity from "@/app/components/icon/svg/opportunity.svg?react";
import Paperclip from "@/app/components/icon/svg/paperclip.svg?react";
import PaperPlaneGradient from "@/app/components/icon/svg/paperplane-gradient.svg?react";
import PaperPlane from "@/app/components/icon/svg/paperplane.svg?react";
import PCGFunds from "@/app/components/icon/svg/pcg-funds.svg?react";
import PDFIcon from "@/app/components/icon/svg/pdf.svg?react";
import PheonixCourtGroupFunds from "@/app/components/icon/svg/Pheonix Court Group Funds.svg?react";
import Pipeline from "@/app/components/icon/svg/pipeline.svg?react";
import PortfolioCompany from "@/app/components/icon/svg/Portfolio Company.svg?react";
import PreviouslyMet from "@/app/components/icon/svg/Previously Met.svg?react";
import ProspectStage from "@/app/components/icon/svg/Prospect Stage.svg?react";
import RadioofFirstinvestedDollar from "@/app/components/icon/svg/radio-of-first-invested-dollar.svg?react";
import Reset from "@/app/components/icon/svg/reset.svg?react";
import Sadface from "@/app/components/icon/svg/sad-face.svg?react";
import SadSmile from "@/app/components/icon/svg/sad-smile.svg?react";
import SandClock from "@/app/components/icon/svg/sand-clock.svg?react";
import Sandglass from "@/app/components/icon/svg/sandglass.svg?react";
import Search from "@/app/components/icon/svg/search.svg?react";
import Sentiment from "@/app/components/icon/svg/sentiment.svg?react";
import Settings from "@/app/components/icon/svg/settings.svg?react";
import Share from "@/app/components/icon/svg/share.svg?react";
import Sidebar from "@/app/components/icon/svg/sidebar.svg?react";
import SignalGradient from "@/app/components/icon/svg/signal-gradient.svg?react";
import Signal from "@/app/components/icon/svg/signal.svg?react";
import Snooze from "@/app/components/icon/svg/snooze.svg?react";
import Solar from "@/app/components/icon/svg/solar.svg?react";
import Sort from "@/app/components/icon/svg/sort.svg?react";
import Star from "@/app/components/icon/svg/star.svg?react";
import Suitcase from "@/app/components/icon/svg/suitcase.svg?react";
import SupportedBy from "@/app/components/icon/svg/supported-by.svg?react";
import TableSort from "@/app/components/icon/svg/table-sort.svg?react";
import TaggedIn from "@/app/components/icon/svg/tagged-in.svg?react";
import Time from "@/app/components/icon/svg/Time.svg?react";
import TopInvestor from "@/app/components/icon/svg/top-investor.svg?react";
import TopInvestors from "@/app/components/icon/svg/top-investors.svg?react";
import TotalInvested from "@/app/components/icon/svg/Total Invested.svg?react";
import TotalCost from "@/app/components/icon/svg/total-cost.svg?react";
import TotalNav from "@/app/components/icon/svg/total-nav.svg?react";
import TotalRaised from "@/app/components/icon/svg/total-raised.svg?react";
import Trello from "@/app/components/icon/svg/trello.svg?react";
import Twitter from "@/app/components/icon/svg/twitter.svg?react";
import UK from "@/app/components/icon/svg/UK.svg?react";
import Unicorn from "@/app/components/icon/svg/unicorn.svg?react";
import UpDownSort from "@/app/components/icon/svg/up-down-sort.svg?react";
import UpDown from "@/app/components/icon/svg/up-down.svg?react";
import USD from "@/app/components/icon/svg/USD.svg?react";
import Wand from "@/app/components/icon/svg/wand.svg?react";

export { Bell, Network, Opportunity, Analysis, MeetThePortfolioIcon };

const iconMap = {
  "": null,
  Add: Plus,
  "Top Investor": TopInvestor,
  "Top Investors": TopInvestors,
  "Number of Basecamp Investors": NoOfInvestors,
  "Latest Excitement Score": LatestExcitementScores,
  "Include Exits & Write Offs": IncludeExists,
  "Total Cost": TotalCost,
  "Total NAV": TotalNav,
  "NAV as a % of Fund": NavAsPercent,
  "First Dollar to Follow-On": FirstDollarToFollow,
  percentage: Percent,
  Insight,
  Lock,
  HandShake,
  NoData,
  BurgerMenu,
  Burger4,
  CardMenu,
  MenuCross,
  BarsIncrease,
  ChatBubble,
  Mail,
  Minimise,
  Maximise,
  Pencil,
  GBP,
  USD,
  EUR,
  CHF,
  Copy,
  Calendar,
  Eye,
  Crown,
  AlertTriangle,
  Link,
  Unlink,
  "Tagged In": TaggedIn,
  "Supported By": SupportedBy,
  "Led By": LedBy,
  "Phoenix Court Group Funds": PCGFunds,
  "Basecamp Funds": BasecampFunds,
  Increase,
  Decrease,
  FillCheck,
  "Filter Chevron Down": FilterChevronDown,
  "Filter Chevron Up": FilterChevronUp,
  "Chevron Down": ChevronDown,
  "Chevron Up": ChevronUp,
  "Chevron Right": ChevronRight,
  "Chevron Left": ChevronLeft,
  MoveDownLeft,
  MoveUpLeft,
  "Added By Me": AddedByMe,
  "Assigned To": AssignedTo,
  Basecamp: Basecamp,
  "Focus Cities": Cities,
  Edit,
  EMEA: EMEA,
  "Excitement Exists": ExcitementExists,
  Futurecorn: Corn,
  "Investment Type": InvestmentType,
  "Last Round Announced": LastRoundAnounced,
  "Last Sentiment Added": LastSentimentAdded,
  "Nav Invested": NavInvested,
  "New Palo Alto": NewPaloAlto,
  "Pheonix Court Group Funds": PheonixCourtGroupFunds,
  "Portfolio Company": PortfolioCompany,
  "Previously Met": PreviouslyMet,
  "Prospect Stage": ProspectStage,
  "Radio of First invested Dollar": RadioofFirstinvestedDollar,
  "Total Invested": TotalInvested,
  UK: UK,
  Unicorn: Unicorn,
  MoveUpRight,
  MoveDownRight,
  Continent: Globe,
  Company,
  LogOut,
  Countries: Flag,
  Filter,
  "Last Round": Receipt,
  MeetThePortfolioIcon,
  "Last Round Amount": Receipt,
  "Total Raised": DollarSign,
  "Months from last Funding": CalendarDays,
  "Target Funding Amt": Receipt,
  Industries: Factory,
  Sectors: Factory,
  Share,
  Paperclip,
  Pipeline,
  Loader: Loader2Icon,
  PaperPlane,
  PaperPlaneGradient,
  Snooze,
  DotIcon,
  Flag,
  Signal,
  Sort,
  Twitter,
  Info,
  InfoOutline,
  LocalGlobe,
  Logo,
  Latitude,
  Solar,
  BadgeInfo,
  StickyNote,
  Linkedin,
  Trash: Delete,
  Check,
  Trello,
  Sadface,
  Sandglass,
  User,
  Rocket,
  Crunchbase,
  Opportunity,
  Network,
  Dealroom,
  Suitcase,
  NotificationNote,
  Star,
  "Shield Alert": ShieldAlert,
  "Target Fund": Crosshair,
  X: X,
  Calendar,
  Employees: Users2,
  LastRound,
  LastRoundAmount,
  LastRoundLead,
  TotalRaised,
  ArrowLeft,
  ArrowRight,
  Reset,
  DoubleArrowLeft,
  DoubleArrowRight,
  ArrowUpCircle,
  Triangle,
  UpDown,
  DownUp,
  UpDownSort,
  DownUpSort,
  CloseCircle,
  Navigate,
  LoopClouds,
  "Raising in 6 Month": LineChart,
  Cmd,
  CmdKey,
  GradientOpportunity,
  GradientSentiment,
  GradientFounder,
  Sentiment,
  Founder,
  CompanyImg,
  Attach,
  Attachment,
  Comment,
  SandClock,
  SadSmile,
  SignalGradient,
  ImageIcon,
  PDFIcon,
  DOCIcon,
  OpportunityPlaceholder,
  Table,
  TableSort,
  Settings,
  Sub: Minus,
  Analysis,
  Wand,
  ExternalLink,
  LineChart,
  NoResults,
  Users,
  AddGradient,
  LoopGradient,
  MoreVertical,
  "Sharing Allowed": Speech,
  "Hidden By Me": EyeOff,
  RefreshCw,
  RefreshCcw,
  // text style related icons
  List,
  ListOrdered,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Pilcrow,
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Code,
  AtSign,
  Quote,
  // keys
  Command,
  Option,
  Search,
  SearchPrimary,
  ArrowBigUp,
  Time,
  //   sidebar
  Sidebar,
};

export type IconType = keyof typeof iconMap;

export type IconProp = {
  type: keyof typeof iconMap;
  fillPath?: string;
  className?: string;
  color?: ColorType;
  width?: number;
  height?: number;
  onClick?: (args) => void;
} & LucideProps;

export const Icon = ({ onClick, width, height, className, color, type = "Filter", ...props }: IconProp) => {
  const IconSVG = iconMap[type];
  if (!IconSVG) {
    return <div className={cn(className, "rounded-full bg-neutral-300")} />;
  }

  return (
    <IconSVG
      width={width}
      height={height}
      onClick={onClick}
      color={color}
      className={cn(className, { "animate-spin": type === "Loader" })}
      {...props}
    />
  );
};

import React, { useState } from "react";

import { EmptySection } from "@/app/screens/opportunities/opportunity/components";
import { OpportunityMaximise } from "@/app/screens/opportunities/opportunity/components/maximise/maximise.tsx";
import { SentimentTable } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-table.tsx";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

import { SentimentChart } from "./sentiment-chart.tsx";
import { SentimentContextProvider, SentimentState, useSentimentContext } from "./sentiment-context.tsx";

const SentimentContent = () => {
  const [maximised, setMaximised] = useState(false);
  const { company } = useOpportunityContext();
  const { state } = useSentimentContext();
  const sentimentData = company?.sentiment?.nodes || [];

  if (
    state === SentimentState.no_data ||
    !company ||
    !sentimentData?.length ||
    !sentimentData.some((data) => data.responses.length)
  ) {
    return <EmptySection text={"No Sentiment Forms Filled"} />;
  }

  return (
    <>
      <div className={"pointer-events-auto flex flex-col items-center justify-between rounded-md bg-white pb-3 pt-5"}>
        <SentimentChart sentimentData={sentimentData} company={company} maximised={false} setMaximised={setMaximised} />
        <SentimentTable sentimentData={sentimentData} />
      </div>
      <OpportunityMaximise setMaximised={setMaximised} maximised={maximised}>
        <SentimentChart
          sentimentData={sentimentData}
          company={company}
          maximised={maximised}
          setMaximised={setMaximised}
        />
      </OpportunityMaximise>
    </>
  );
};

export const Sentiment = () => {
  return (
    <SentimentContextProvider>
      <SentimentContent />
    </SentimentContextProvider>
  );
};

import { Fragment, useState } from "react";

import { Popover, Transition } from "@headlessui/react";
import { observer } from "mobx-react";
import { usePopper } from "react-popper";

import { cn } from "@/lib/utils.ts";

import { Icon, Text } from "@/app/components";
import { opportunityFilterStore } from "@/app/screens/opportunities";
import { opportunitiesSort, sortDirections } from "@/app/stores/filter.store.tsx";

type SortProps = {
  containerClassName?: string;
  onClick?: () => void;
};

export const Sort = observer(function Sort({ containerClassName, onClick }: SortProps) {
  console.info(
    opportunityFilterStore.ordering?.value,
    opportunitiesSort.find((item) => item.value === opportunityFilterStore.ordering?.value)?.label,
  );

  const sortLabel = opportunitiesSort.find((item) => item.value === opportunityFilterStore.ordering?.value)?.label;
  const sortDirection = opportunityFilterStore.ordering?.dir === "ASC" ? "Ascending" : "Descending";

  const [referenceElement, setReferenceElement] = useState<null>();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {});

  return (
    <div className={cn("z-30 flex flex-row items-center", containerClassName)}>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              onClick={() => {
                onClick?.();
              }}
              ref={setReferenceElement as any}
              className={cn(
                "flex w-fit justify-end font-semibold outline-none transition-all duration-200 ease-in-out lg:min-w-22",
              )}
            >
              <div
                className={cn(
                  "flex items-center rounded-xs bg-transparent px-3 py-2 text-xs text-neutral-900 hover:bg-neutral-300 active:bg-neutral-400",
                  open && "bg-neutral-300",
                )}
              >
                <span className={"select-none hover:text-neutral-900 lg:text-[12px] 2xl:text-[14px]"}>{sortLabel}</span>
                <Icon
                  type={sortDirection === "Ascending" ? "UpDown" : "DownUp"}
                  className={"ml-2 text-black lg:size-3 2xl:size-4"}
                />
              </div>
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel
                className="absolute z-10 mt-4 rounded-md shadow-xs"
                ref={setPopperElement as any}
                style={styles.popper}
                {...attributes.popper}
              >
                <div className="relative flex flex-col rounded-md bg-white p-4">
                  <Icon
                    type={"Triangle"}
                    width={15}
                    color={"white"}
                    fill={"white"}
                    height={15}
                    className={cn("fixed -top-3 self-end", `mr-[20%]`)}
                  />
                  <div className={"flex flex-col space-y-2"}>
                    <div className={"flex flex-col space-y-2 px-1"}>
                      {opportunitiesSort.map(({ value, label, defaultDirection }) => {
                        const isSelected = sortLabel === label;
                        return (
                          <div
                            key={value}
                            onClick={() => {
                              if (value === "DEFAULT") {
                                opportunityFilterStore.ordering = {
                                  ...opportunityFilterStore.defaultOrdering,
                                };
                                return;
                              }

                              opportunityFilterStore.ordering = {
                                dir: defaultDirection,
                                value,
                              };
                            }}
                            className={cn(
                              "flex cursor-pointer items-center justify-between px-4 py-2.5 hover:rounded-sm hover:bg-background",
                              isSelected ? "rounded-sm bg-neutral-100" : "",
                            )}
                          >
                            <Text text={label} className={"select-none text-nowrap"} />
                            <Icon
                              type={"FillCheck"}
                              width={16}
                              height={16}
                              className={cn("ml-5 text-primary", isSelected ? "" : "opacity-0")}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      <Text text={"Sort by"} weight={"light"} className={"mx-6 my-3 text-neutral-400"} />
                      {sortDirections.map(({ value, label }) => {
                        const isSelected = sortDirection === label;
                        return (
                          <div
                            key={value}
                            onClick={() => {
                              opportunityFilterStore.ordering = {
                                ...opportunityFilterStore.ordering,
                                dir: value,
                              };
                            }}
                            className={cn(
                              "flex cursor-pointer items-center justify-between px-4 py-2.5 hover:rounded-sm hover:bg-background",
                              isSelected ? "rounded-md bg-neutral-100" : "",
                            )}
                          >
                            <div className={"flex items-center"}>
                              <Icon
                                type={label === "Ascending" ? "UpDown" : "DownUp"}
                                width={16}
                                height={16}
                                className={cn("mr-2")}
                              />
                              <Text text={label} className={"select-none text-nowrap"} />
                            </div>
                            <Icon
                              type={"FillCheck"}
                              width={16}
                              height={16}
                              className={cn("ml-5 text-primary", isSelected ? "" : "opacity-0")}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
});

// export const SortList = () => {
//   const [sort, setSort] = useState(opportunitiesSort);
//   return (
//     <div
//       className={
//         "m-0 flex h-4 w-fit items-center py-0 md:divide-x md:divide-neutral-300"
//       }
//     >
//       {sort.map(({ values, index, selected, sortKey }, i) => (
//         <Text
//           key={i}
//           text={values[index]}
//           color={selected ? "text-primary-300" : "text-black"}
//           className={cn(
//             "w-fit cursor-pointer px-4 active:bg-background sm:mx-2 sm:rounded-lg sm:border sm:py-2 md:rounded-none md:border-0 md:py-0",
//             selected ? "sm:border-primary-300" : "sm:border-neutral-400"
//           )}
//           onClick={() => {
//             setSort((prevSort) => {
//               const prevSortCopy = structuredClone([...prevSort]);
//               if (selected) {
//                 if (
//                   prevSortCopy[i]["index"] ===
//                   prevSortCopy[i]["values"].length - 1
//                 ) {
//                   prevSortCopy[i]["index"] = 0;
//                 } else {
//                   prevSortCopy[i]["index"]++;
//                 }
//               }
//               opportunityFilterStore.changeChoices({
//                 category: "ordering",
//                 value: sortKey[prevSortCopy[i]["index"]],
//               });
//
//               return prevSortCopy.map((e, idx) => ({
//                 ...e,
//                 selected: idx === i,
//               }));
//             });
//           }}
//         />
//       ))}
//     </div>
//   );
// };

import toast from "react-hot-toast";

import {
  EmailOpportunityAction,
  GetCompaniesDocument,
  GetCompanyDocument,
  GetCompanyUpdatesDocument,
  GetDashboardCompaniesDocument,
  GetFeedbackTemplatesDocument,
  GetForwardedCompaniesDocument,
  GetUserHighlightsDocument,
} from "@/gql/graphql";

import { useSetOpportunityEmailMutation, useSetNotificationStatusMutation } from "@/app/service/dashboard.gql.ts";
import modalStore from "@/app/stores/modal.store";
import refetchStore from "@/app/stores/refetch-store.ts";

import { ConfirmModal } from "../../modal";
import { NotificationStatus } from "../dashboard.types";

type UseRemoveEmailParams = {
  emailId?: string;
  notificationId?: string;
  successMessage?: string;
};

export const useSetEmailStatus = ({
  emailId,
  notificationId,
  successMessage = "Successfully removed email",
}: UseRemoveEmailParams) => {
  const [updateCompanyEmail, { loading: setOpportunityEmailLoading }] = useSetOpportunityEmailMutation();
  const [setNotificationStatus, { loading: setNotificationStatusLoading }] = useSetNotificationStatusMutation();

  const handleRemove = () => {
    const refetchQueries = refetchStore.getRefetchQueries([
      GetCompanyDocument,
      GetCompaniesDocument,
      GetDashboardCompaniesDocument,
      GetUserHighlightsDocument,
      GetFeedbackTemplatesDocument,
      GetCompanyUpdatesDocument,
      GetForwardedCompaniesDocument,
    ]);

    modalStore.open(ConfirmModal, {
      props: {
        title: "Are you sure?",
        subTitle: `By confirming, you will decline this notification`,
        handleConfirm: async () => {
          const promises: Promise<unknown>[] = [];

          if (notificationId) {
            promises.push(
              setNotificationStatus({
                variables: { id: notificationId, status: NotificationStatus.DECLINED },
                refetchQueries,
              }).catch(),
            );
          }

          if (emailId) {
            promises.push(
              updateCompanyEmail({
                variables: {
                  input: {
                    action: EmailOpportunityAction.Decline,
                    emailOpportunityId: emailId,
                    notificationId,
                  },
                },
                refetchQueries,
              }),
            );
          }

          await Promise.all(promises);
          toast.success(successMessage);
          modalStore.close();
        },
      },
    });
  };

  const handleMarkAsDone = () => {
    if (notificationId) {
      const refetchQueries = refetchStore.getRefetchQueries([
        GetCompanyDocument,
        GetCompaniesDocument,
        GetDashboardCompaniesDocument,
        GetUserHighlightsDocument,
        GetFeedbackTemplatesDocument,
        GetCompanyUpdatesDocument,
        GetForwardedCompaniesDocument,
      ]);
      setNotificationStatus({
        variables: { id: notificationId, status: NotificationStatus.COMPLETED },
        refetchQueries,
      }).then(() => {
        toast.success("Successfully marked as done");
      });
    }
  };

  return {
    handleRemove,
    handleMarkAsDone,
    loading: setOpportunityEmailLoading || setNotificationStatusLoading,
    loadingNotification: setNotificationStatusLoading,
  };
};

import { useAuthContext } from "@/app/contexts/auth";
import { gql, useLazyQuery } from "@apollo/client";
import { Checkbox, Text, Icon } from "app/components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Settings } from "./settings";
import { motion, AnimatePresence } from "framer-motion";

const items = [
  "Easily search through PCG’s collective network - people and organisations",
  "Apply advanced filters to PCG’s collective network to create bespoke lists of people or organisations (follow-on funding lists, event invite lists, talent, etc)" +
    "Get more insight on people and organisations in our network via 3rd party data enrichment",
  "See connection strength between PCG team members and people/organisations in our network (number of email interactions, meetings, etc)",
  "Automatic sentiment form creation using calendar integration",
  "Network sourcing statistics and attribution",
];

const contactsLookupQuery = gql`
  query networkContactsLookup($q: String, $sort: String, $filters: String) {
    contacts: networkContactsLookup(q: $q, sort: $sort, filterS: $filters) {
      nodes {
        email
        name
        tags
        totalTo
        totalFrom
        lastTo
        lastFrom
        connections
        connectionStrength
        contactType
      }
    }
  }
`;

const numberFormat = Intl.NumberFormat("en-GB");

const dateTimeFormat = new Intl.DateTimeFormat("en-GB", {
  day: "numeric",
  month: "short",
  year: "numeric",
});
export function NetworkDashboard() {
  const { myRoles, loaded } = useAuthContext();

  if (!loaded) return null;
  // TODO: make it enum & not a string !?
  if (myRoles.includes("USER_ROLE_NETWORK")) {
    return <NetworkDashboardBeta />;
  }

  return <NetworkDashboardComing />;
}

enum Column {
  Contact = "contact",
  Connectivity = "connectivity",
  LIncoming = "lincoming",
  LOutgoing = "loutgoing",
  NIncoming = "nincoming",
  NOutgoing = "noutgoing",
  Connections = "connections",
}

enum SortDirection {
  Asc = "asc",
  Desc = "desc",
}

type ColumnMapValue = {
  title: string;
  extraTheadClasses?: string;
  ascText?: string;
  descText?: string;
};

const columns = new Map<Column, ColumnMapValue>([
  [Column.Contact, { title: "Contact", extraTheadClasses: "rounded-tl-md", ascText: "A-Z", descText: "Z-A" }],
  [
    Column.Connectivity,
    { title: "Connectivity", extraTheadClasses: "text-center", ascText: "Low to high", descText: "High to low" },
  ],
  [
    Column.LIncoming,
    {
      title: "Last Incoming",
      extraTheadClasses: "text-center",
      ascText: "Newest to oldest",
      descText: "Oldest to newest",
    },
  ],
  [
    Column.LOutgoing,
    {
      title: "Last Outgoing",
      extraTheadClasses: "text-center",
      ascText: "Newest to oldest",
      descText: "Oldest to newest",
    },
  ],
  [Column.NIncoming, { title: "# Incoming", ascText: "Fewest to most", descText: "Most to fewest" }],
  [Column.NOutgoing, { title: "# Outgoing", ascText: "Fewest to most", descText: "Most to fewest" }],
  [
    Column.Connections,
    { title: "Connections", extraTheadClasses: "rounded-tr-md", ascText: "Fewest to most", descText: "Most to fewest" },
  ],
]);

const sortNodes = Array.from(columns.entries()).map(([column, c]) => {
  return {
    column: column,
    title: c.title,
    nodes: [SortDirection.Asc, SortDirection.Desc].map((direction) => {
      return {
        title: c[`${direction}Text`] || direction,
        extraAttrs: { "data-column-next-sort": sortPairValueToString(column, direction) },
        closeOnClick: true,
      };
    }),
  };
});
const ColumnValues = Object.values(Column);
const SortDirectionValues = Object.values(SortDirection);
const defaultColumn = Column.Connectivity;
const defaultSortDirection = SortDirection.Desc;

function sortPairValueFromString(v?: string | null): [Column, SortDirection] {
  if (!v) return [defaultColumn, defaultSortDirection];
  const [desiredColumn, desiredSortDirection] = v.split("_");
  const resolvedColumn = ColumnValues.includes(desiredColumn as Column) ? (desiredColumn as Column) : defaultColumn;
  const resolvedSortDirection = SortDirectionValues.includes(desiredSortDirection as SortDirection)
    ? (desiredSortDirection as SortDirection)
    : defaultSortDirection;

  return [resolvedColumn, resolvedSortDirection];
}

function sortPairValueToString(column: Column, sortDirection: SortDirection): string {
  return [column, sortDirection].join("_");
}

type TableHeaderCellProps = {
  column: Column;
  sortPair: [Column, SortDirection];
};

function TableHeaderCell(props: TableHeaderCellProps) {
  const columnInfo = columns.get(props.column);
  if (!columnInfo) throw new Error(`Unsupported column ${props.column}`);
  let classes = "px-4 py-4 font-medium text-neutral text-left bg-white whitespace-nowrap cursor-pointer select-none ";
  if (columnInfo.extraTheadClasses) {
    classes += columnInfo.extraTheadClasses;
  }

  let nextDirection: SortDirection;
  let imgStyle: Record<string, string>;
  if (props.sortPair[0] === props.column) {
    if (props.sortPair[1] === SortDirection.Asc) {
      nextDirection = SortDirection.Desc;
      imgStyle = { transform: "scaleY(-1)" };
    } else {
      imgStyle = {};
      nextDirection = SortDirection.Asc;
    }
  } else {
    nextDirection = SortDirection.Asc;
    imgStyle = { visibility: "hidden" };
  }
  const nextSort = sortPairValueToString(props.column, nextDirection);

  return (
    <th className={classes} data-column-next-sort={nextSort}>
      {columnInfo.title}{" "}
      <img
        className="inline transition-transform duration-200 ease-in-out"
        style={imgStyle}
        src="/assets/icons/arrow-sort-desc.svg"
      />
    </th>
  );
}

type TableHeaderProps = {
  sortPair: [Column, SortDirection];
  onTableHeaderClick: (e: React.MouseEvent<HTMLElement>) => void;
};

function TableHeader(props: TableHeaderProps) {
  return (
    <thead className="hidden shadow-sm @7xl:table-row-group sticky top-[127px] lg:top-[65px] z-10">
      <tr className=" leading-tight" onClick={props.onTableHeaderClick}>
        {[...columns].map(([column]) => (
          <TableHeaderCell key={column} column={column} sortPair={props.sortPair} />
        ))}
      </tr>
    </thead>
  );
}

type TableProps = {
  sortPair: [Column, SortDirection];
  handleTableHeaderClick: (e: React.MouseEvent<HTMLElement>) => void;
  handleListClick: (e: React.MouseEvent<HTMLElement>) => void;
  data: any;
};

function Table(props: TableProps) {
  const { sortPair, handleTableHeaderClick, handleListClick, data } = props;
  return (
    <table className="divide-y flex w-full @7xl:table text-[14px]">
      <TableHeader sortPair={sortPair} onTableHeaderClick={handleTableHeaderClick} />
      <tbody
        className="grid grid-cols-1 gap-2 @3xl:grid-cols-2 @5xl:grid-cols-3 @7xl:table-row-group @7xl:divide-y @7xl:bg-white @7xl:gap-0"
        onClick={handleListClick}
      >
        {data?.contacts?.nodes?.map((contact) => <Contact key={contact.email} {...contact} />)}
      </tbody>
      <tfoot className="hidden @7xl:table-row-group">
        <tr>
          <th colSpan={7} className="p-2 rounded-b-md bg-white"></th>
        </tr>
      </tfoot>
    </table>
  );
}

enum FlagFilterKey {
  EngagedOnly = "engaged_only",
}

enum FilterType {
  Flag = "flag",
}

type FlagFilter = {
  key: FlagFilterKey.EngagedOnly;
  value: boolean;
  type: FilterType.Flag;
};

type Filters = Map<FlagFilterKey, FlagFilter>;

const defaultFlagValues = new Map([[FlagFilterKey.EngagedOnly, true]]);

function filtersToStr(filters: Filters): string {
  const res: string[] = [];
  for (const [_, filter] of filters) {
    if (filter.type === "flag") {
      if (Object.values(FlagFilterKey).includes(filter.key as FlagFilterKey)) {
        const value = filter.value ? "t" : "f";
        res.push([filter.key, value].join("-"));
      }
    }
  }
  return res.join(":");
}

function filtersToStrGql(filters: Filters): string {
  const res: string[] = [];
  for (const [_, filter] of filters) {
    if (Object.values(FlagFilterKey).includes(filter.key as FlagFilterKey)) {
      if (filter.type === "flag" && filter.value === true) {
        res.push(filter.key);
      }
    }
  }
  return res.join(":");
}

function filtersFromStr(str?: string | null): Filters {
  const filters: Filters = new Map();
  // NOTE: Default values
  for (const [key, value] of defaultFlagValues.entries()) {
    filters.set(key, { key, value, type: FilterType.Flag });
  }

  if (!str) {
    return filters;
  }

  const splited = str.split(":");
  for (const maybeFilter of splited) {
    const [maybeKey, maybeValue] = maybeFilter.split("-");
    if (!maybeKey || !maybeValue) continue;
    if (Object.values(FlagFilterKey).includes(maybeKey as FlagFilterKey)) {
      let value: boolean;
      if (maybeValue === "t") {
        value = true;
      } else if (maybeValue === "f") {
        value = false;
      } else {
        continue;
      }
      const filterKey = maybeKey as FlagFilterKey;
      filters.set(filterKey, { key: filterKey, value, type: FilterType.Flag });
    }
  }
  return filters;
}

export function NetworkDashboardBeta() {
  const [params, setParams] = useSearchParams();
  const [q, setQ] = useState(params.get("q") || "");
  const [debounceQ, setDebounceQ] = useState("");
  const selectedFilters = filtersFromStr(params.get("filter"));
  const [filters, setFilters] = useState(selectedFilters);
  const [sortPair, setSortPair] = useState<[Column, SortDirection]>(sortPairValueFromString(params.get("sort")));
  const [sortModalOpened, setSortModalOpened] = useState(false);
  const [lookedUp, setLookedUp] = useState(!!params.size);
  const [lookup, { data, loading, previousData }] = useLazyQuery(contactsLookupQuery, {
    variables: { q, order: sortPairValueToString(...sortPair), filters: filtersToStrGql(filters) },
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!params.size) {
      setQ("");
      setDebounceQ("");
      setLookedUp(false);
    }
  }, [params]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceQ(q);
    }, 100);
    return () => {
      clearTimeout(handler);
    };
  }, [q]);

  useEffect(() => {
    const sortPairString = sortPairValueToString(...sortPair);
    const filtersStringGql = filtersToStrGql(filters);
    if (debounceQ) {
      lookup({
        variables: { q: debounceQ, sort: sortPairString, filters: filtersStringGql },
      });
    }
  }, [debounceQ, sortPair, filters]);

  useEffect(() => {
    if (!lookedUp) return;
    const sortPairString = sortPairValueToString(...sortPair);
    const filtersString = filtersToStr(filters);
    const searchParams = new URLSearchParams(params);
    searchParams.set("q", debounceQ);
    searchParams.set("sort", sortPairString);
    searchParams.set("filter", filtersString);
    setParams(searchParams, { replace: true });
  }, [debounceQ, sortPair, filters, lookedUp]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLookedUp(true);
      setQ(e.target.value);
    },
    [setQ]
  );

  // PERF: have one onClick handler for huge list instead of every element
  const handleListClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (event.target instanceof Element) {
        const clickedContact = event.target?.closest("[data-contact-email]")?.getAttribute("data-contact-email");
        if (clickedContact) {
          event.preventDefault();
          navigate(
            `/network/contact/${clickedContact}?fq=${debounceQ}&fsort=${sortPairValueToString(...sortPair)}&ffilter=${filtersToStr(filters)}`
          );
        }
      }
    },
    [navigate, debounceQ, sortPair, filters]
  );

  const handleSortClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof Element) {
      const nextPairString = event.target?.closest("[data-column-next-sort]")?.getAttribute("data-column-next-sort");
      if (nextPairString) {
        const nextPair = sortPairValueFromString(nextPairString);
        event.preventDefault();
        setSortPair(nextPair);
      }
    }
  }, []);

  const handleEngagedClick = useCallback((event: React.MouseEvent<HTMLInputElement>) => {
    const val = !!event.currentTarget?.checked;
    const incomingFilters = new Map(filters);
    incomingFilters.set(FlagFilterKey.EngagedOnly, {
      key: FlagFilterKey.EngagedOnly,
      type: FilterType.Flag,
      value: val,
    });
    setFilters(incomingFilters);
  }, []);

  let inputWrapperClasses: string;
  let inputClasses: string;
  let inputTitleClasses: string;
  let inputInnerWrapperClasses: string;
  let engagedClasses: string;
  let sortModalCtrlClasses: string;

  let displayedData = undefined;
  if (debounceQ?.length) {
    if (data) displayedData = data;
    else if (loading && previousData) displayedData = previousData;
  }
  if (!lookedUp) {
    inputWrapperClasses = "flex flex-col items-center justify-center flex-grow";
    inputClasses = "my-2 w-full rounded-md border border-neutral-300 px-3 py-2 pl-10 mb-0";
    inputTitleClasses = "text-[32px] font-semibold mb-2 text-center";
    inputInnerWrapperClasses = "w-full max-w-[800px]";
    engagedClasses = "hidden";
    sortModalCtrlClasses = "hidden";
  } else {
    inputWrapperClasses = "sticky mb-1 p-0 @7xl:mb-0 @7xl:py-2 top-[50px] lg:top-[0px]  top-0 z-10 bg-[#f0f0f0]";
    inputClasses = "my-2 w-full rounded-md border border-neutral-300 px-3 py-2 pl-10 mb-0 pr-[170px]";
    inputTitleClasses = "hidden";
    inputInnerWrapperClasses = "w-full";
    engagedClasses = "mr-2";
    sortModalCtrlClasses = "p-2 w-[32px] @7xl:hidden";
  }

  const inputIconType = loading ? "Loader" : "Search";
  const labelStyle = loading ? "absolute top-[19px] left-[11px]" : "absolute top-[21px] left-[13px]";

  const handleSortModalClose = useCallback(() => {
    setSortModalOpened(false);
  }, []);

  const handleSortModalOpen = useCallback(() => {
    setSortModalOpened(true);
  }, []);
  const sortProps = {
    onClose: handleSortModalClose,
    node: {
      title: "Sort By",
      nodes: sortNodes.map((e) => {
        if (e.column === sortPair[0]) {
          return { ...e, selectedText: columns.get(e.column)![`${sortPair[1]}Text`] };
        }
        return e;
      }),
    },
  };
  return (
    <div className="@container font-barlow flex w-full mt-[50px] lg:mt-0 max-w-[100%] h-width">
      <div className="flex flex-col p-4 @7xl:p-8 w-full">
        <div className={inputWrapperClasses}>
          <div className={inputTitleClasses}>Network. Alpha.</div>
          <div className={inputInnerWrapperClasses}>
            <div className="relative w-full">
              <label htmlFor="network-search-input" className={labelStyle}>
                <Icon type={inputIconType} width={20} />
              </label>
              <input
                id="network-search-input"
                type="text"
                autoComplete="off"
                autoFocus
                onChange={handleInputChange}
                className={inputClasses}
                value={q}
                placeholder="Search by name, email address or domain."
              />

              {!lookedUp ? (
                <div className="">
                  <LandingMetrics />
                </div>
              ) : null}
              <div className="absolute top-0 right-2 flex flex-wrap items-center">
                <div className={engagedClasses}>
                  <Checkbox
                    checked={filters.get(FlagFilterKey.EngagedOnly)?.value}
                    onClick={handleEngagedClick}
                    label={"Engaged Only"}
                    containerClassName={"my-2"}
                    labelClassName={"text-black font-medium"}
                  />
                </div>

                <div className={sortModalCtrlClasses} onClick={handleSortModalOpen}>
                  <Icon type="Sort" className="size-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {lookedUp && sortModalOpened ? (
          <div onClick={handleSortClick}>
            <Settings {...sortProps} />
          </div>
        ) : null}
        {lookedUp ? (
          <Table
            data={displayedData}
            sortPair={sortPair}
            handleTableHeaderClick={handleSortClick}
            handleListClick={handleListClick}
          />
        ) : null}
      </div>
    </div>
  );
}

type ContactProps = {
  email: string;
  name: string[];
  tags: string[];
  totalTo: number;
  totalFrom: number;
  lastTo: string;
  lastFrom: string;
  connections: string[];
  key?: string;
  connectionStrength: number;
};

function formatDate(dateTime?: string): string {
  if (!dateTime) return "—";

  // HACK: we expect data w/ not timezone here
  // but we know it Zulu so gonna treat it like one
  return dateTimeFormat.format(new Date(`${dateTime}Z`));
}

function Contact(props: ContactProps) {
  const fakeHeaderClasses = "font-medium text-neutral text-[12px] @7xl:hidden";

  // NOTE: connectionStrength is a real number [0,1]
  // we need to show low/med/high baced on linear percentile value
  // i.e. [0-33] [33-66] [66-100]
  const connectivityIcons = ["low", "medium", "high"];
  const connectivityIconType = connectivityIcons[Math.min(2, Math.floor((props.connectionStrength || 0) * 3))];
  return (
    <tr
      className="flex flex-wrap p-2 rounded-md bg-white card w-full overflow-hidden  @7xl:table-row cursor-pointer relative @7xl:p-0"
      data-contact-email={props.email}
      key={props.email}
    >
      <td className="flex flex-col p-2 mr-7 @7xl:w-[300px] overflow-hidden @7xl:px-2 @7xl:p-4 w-full  @7xl:table-cell @7xl:mr-0 @7xl:pl-4">
        <div className="line-clamp-1 @7xl:w-[300px] overflow-hidden text-ellipsis break-all w-auto font-semibold leading-normal text-black">
          {props.email}
        </div>
        <div className="line-clamp-1  @7xl:w-[300px]  font-[500] overflow-hidden text-ellipsis break-all text-neutral-700 sm:text-sm">
          {props.name.join(", ")}
        </div>
      </td>
      <td className="flex absolute top-6 right-6 @3xl:top-4 @3xl:right-4 @7xl:px-2 @7xl:p-4 @7xl:text-center @7xl:w-[1px] @7xl:table-cell @7xl:static">
        <img className="inline" src={`/assets/icons/connectivity-${connectivityIconType}.svg`} />
      </td>
      <td className="flex flex-col p-2 @7xl:table-cell w-1/4 @7xl:px-2 @7xx:p-4 text-black @7xl:text-center whitespace-nowrap @7xl:w-[1px]">
        <div className={fakeHeaderClasses}>Last Incoming</div>
        <div>{formatDate(props.lastFrom)}</div>
      </td>
      <td className="flex flex-col p-2 @7xl:table-cell w-1/4 @7xl:px-2 @7xl:p-4 text-black @7xl:text-center whitespace-nowrap @7xl:w-[1px]">
        <div className={fakeHeaderClasses}>Last Outgoing</div>
        <div>{formatDate(props.lastTo)}</div>
      </td>
      <td className="flex flex-col p-2 w-1/4 @7xl:pl-4 @7xl:p-4 whitespace-nowrap @7xl:w-[1px] @7xl:table-cell ">
        <div className={fakeHeaderClasses}># Incoming</div>
        <div>
          <img className="inline mr-1" src="/assets/icons/mail-incoming.svg" />
          {numberFormat.format(props.totalFrom)}
        </div>
      </td>{" "}
      <td className="flex flex-col p-2 w-1/4 @7xl:pl-4 @7xl:p-4 whitespace-nowrap @7xl:w-[1px] @7xl:table-cell">
        <div className={fakeHeaderClasses}># Outgoing</div>
        <div>
          <img className="inline mr-1" src="/assets/icons/mail-outgoing.svg" />
          {numberFormat.format(props.totalTo)}
        </div>
      </td>
      <td className="flex flex-col p-2 w-full @7xl:pl-4 @7xl:p-4 @7xl:table-cell @7xl:w-auto">
        <div className={fakeHeaderClasses}>Connections</div>
        <div className="line-clamp-2  font-[500] overflow-hidden text-ellipsis text-neutral-700 sm:text-sm leading-normal">
          <span className="bg-neutral-100 px-2 py-1 rounded-sm text-center text-xs text-neutral w-auto">
            {props.connections.length}
          </span>{" "}
          {props.connections.join(", ")}
        </div>
      </td>
    </tr>
  );
}

export function NetworkDashboardComing() {
  return (
    <div className={"flex w-full flex-col items-center justify-center p-8 pt-[10dvh] lg:pt-0"}>
      <Text text={"🚀 Exciting news! Network coming soon!"} type={"h5"} className={"mb-8 text-center"} />
      <Text
        text={
          "Network is on the way to our web app! While it's not quite here yet, here's a glimpse of what you can expect. Stay tuned for updates!"
        }
        type={"subtitle"}
        color={"text-neutral-700"}
        className={"text-center"}
      />
      <div className={"mt-8 space-y-3.5 lg:w-[50%]"}>
        {items.map((item, index) => (
          <div
            className={
              "flex cursor-pointer select-none items-center rounded-md bg-white px-3 py-5 shadow-sm transition-transform duration-200 ease-in-out hover:scale-[1.03] lg:p-6"
            }
          >
            <Text text={String(index + 1)} className={"mr-2 rounded-sm bg-neutral-100 px-3 py-2"} />
            <Text text={item} color={"text-neutral-600"} />
          </div>
        ))}
      </div>
    </div>
  );
}

function LandingMetrics() {
  // HACK:TODO: data shoudl be coming from db in future
  const timestamp = +new Date("2024-11-13T20:42:00");
  const engIpmPair = useMemo(() => getIpmPair(5.5), []);
  const contactsIpmPair = useMemo(() => getIpmPair(0.05 / 60), []);
  const companiesIpmPair = useMemo(() => getIpmPair(0.001 / 60), []);
  const engInitialValue = 13206923;
  const contactsInitialValue = 93399;
  const companiesInitialValue = 47938;
  const daysPair = useMemo(() => getDaysPair(timestamp), [timestamp]);
  return (
    <div className="flex flex-col @xl:flex-row justify-around">
      <div className="mx-auto @xl:mx-0  mt-8">
        <AnimatedCounter
          initialValue={contactsInitialValue}
          ipmPair={contactsIpmPair}
          timestamp={timestamp}
          daysPair={daysPair}
        />
        <div className="text-center -mt-1 font-medium text-neutral">Contacts</div>
      </div>

      <div className="mx-auto @xl:mx-0  mt-8">
        <AnimatedCounter
          initialValue={companiesInitialValue}
          ipmPair={companiesIpmPair}
          timestamp={timestamp}
          daysPair={daysPair}
        />
        <div className="text-center -mt-1 font-medium text-neutral">Companies</div>
      </div>

      <div className="mx-auto @xl:mx-0  mt-8">
        <AnimatedCounter
          initialValue={engInitialValue}
          ipmPair={engIpmPair}
          timestamp={timestamp}
          daysPair={daysPair}
        />
        <div className="text-center -mt-1 font-medium text-neutral">Connections</div>
      </div>
    </div>
  );
}

type AnimatedCounterProps = {
  initialValue: number;
  ipmPair: IpmPair;
  timestamp: number;
  daysPair: DaysPair;
};

function AnimatedCounter(props: AnimatedCounterProps) {
  const { initialValue, ipmPair, daysPair, timestamp } = props;
  const minuteEntropy = useMemo(() => createMinuteEntropy(), []);
  const [count, setCount] = useState(shiftValue(initialValue, ipmPair, daysPair, timestamp, minuteEntropy));
  const minIntervalMs = 500;
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    function counterRoutine() {
      setCount(() => shiftValue(initialValue, ipmPair, daysPair, timestamp, minuteEntropy));
      timeoutId = setTimeout(counterRoutine, minIntervalMs);
    }

    counterRoutine();
    return () => clearTimeout(timeoutId);
  }, []);

  const formattedCount = Math.round(count).toLocaleString();
  const digits = formattedCount.split("");

  return (
    <div className="flex font-medium text-black text-[28px]">
      {digits.map((digit, index) => (
        <AnimatePresence mode="popLayout" key={index}>
          <motion.span
            key={`${digit}-${index}`} // Unique key for each character
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 20, opacity: 0 }}
            transition={{ duration: 0.8 }}
            style={{
              display: "inline-block",
              width: digit === "," ? "0.5ch" : "1ch", // Narrow width for commas
              textAlign: "center",
            }}
          >
            {digit}
          </motion.span>
        </AnimatePresence>
      ))}
    </div>
  );
}

const IS_WORKING_HOURS_FLAG: boolean = !!localStorage.NEVERSLEEP;
function shiftValue(
  initial: number,
  ipmPair: IpmPair,
  daysPair: DaysPair,
  timestamp: number,
  entropy: (sec: number) => number
): number {
  const now = new Date();
  const offset = getValueOffset(ipmPair, daysPair, timestamp);
  const currentHour = now.getUTCHours();
  const isWorkingHours = IS_WORKING_HOURS_FLAG || (currentHour >= 9 && currentHour < 17);
  const seconds = now.getSeconds();
  const entropyVal = entropy(seconds);
  const ipm = isWorkingHours ? ipmPair.workingIpm : ipmPair.nonWorkingIpm;
  const shift = entropyVal * ipm;
  return initial + offset + shift;
}

type DaysPair = {
  workingDays: number;
  nonWorkingDays: number;
};

function getDaysPair(timestamp: number): DaysPair {
  const start = new Date(timestamp);
  const now = new Date();

  let workingDays = 0;
  let nonWorkingDays = 0;

  let date = new Date(start);
  for (;;) {
    date.setDate(date.getDate() + 1);
    if (date > now) break;
    const day = date.getUTCDay();
    if (day === 0 || day === 6) {
      nonWorkingDays++;
    } else {
      workingDays++;
    }
  }

  return { workingDays, nonWorkingDays };
}

function getValueOffset(ipmPair: IpmPair, daysPair: DaysPair, timestamp: number): number {
  const now = new Date();
  // NOTE: values from the future !?
  // still return initial
  // should we throw?
  if (+now <= timestamp) {
    return 0;
  }

  const { workingIpm, nonWorkingIpm } = ipmPair;
  const { workingDays, nonWorkingDays } = daysPair;
  const minutesPerDay = 60 * 24;
  const workingHourStarts = 9;
  const workingHourEnds = 17;

  let offset = workingIpm * minutesPerDay * workingDays + nonWorkingIpm * minutesPerDay * nonWorkingDays;

  //  hours from start of the DAY UTC non working
  const currentHour = now.getUTCHours();
  offset += Array.from({ length: currentHour }, (_, hour) =>
    IS_WORKING_HOURS_FLAG || (hour >= workingHourStarts && hour < workingHourEnds)
      ? 60 * workingIpm
      : 60 * nonWorkingIpm
  ).reduce((acc, c) => acc + c, 0);

  // minutes
  const currentMinutes = now.getUTCMinutes();
  offset +=
    IS_WORKING_HOURS_FLAG || (currentHour >= workingHourStarts && currentHour < workingHourEnds)
      ? currentMinutes * workingIpm
      : currentMinutes * nonWorkingIpm;

  return offset;
}

type IpmPair = {
  workingIpm: number;
  nonWorkingIpm: number;
};

function getIpmPair(itm: number): IpmPair {
  const workingFactor = 0.9; // amount of work performed during working hours
  const notWorkingFactor = 1 - workingFactor;
  const totalDays = 31;
  const workingDays = 22;
  const minPerHour = 60;
  const hourPerDay = 24;
  const workingHours = 8;
  const minPerMonth = totalDays * hourPerDay * minPerHour;
  const workingMinPerMonth = workingDays * workingHours * minPerHour;
  const nonWorkingMinPerMonth = minPerMonth - workingMinPerMonth;
  const totalIncrementPerMonth = itm * minPerMonth;
  return {
    workingIpm: (totalIncrementPerMonth * workingFactor) / workingMinPerMonth,
    nonWorkingIpm: (totalIncrementPerMonth * notWorkingFactor) / nonWorkingMinPerMonth,
  };
}

function genEntropyPercentages() {
  const totalSeconds = 60;
  const totalPercentage = 100;
  const rawValues = Array.from({ length: totalSeconds }, (_, i) => {
    let base = Math.abs(Math.sin(i * 0.15) * 0.5 + 0.5);
    if (i % 6 === 0) base *= 2.4;
    else if (i % 4 === 0) base *= 1.7;
    else if (i % 3 === 0) base *= 0.2;
    return base;
  });
  const sumRawValues = rawValues.reduce((sum, value) => sum + value, 0);
  const percentages = rawValues.map((value) => ((value / sumRawValues) * totalPercentage) / 100);
  return percentages;
}

function createMinuteEntropy() {
  const cumulativePercentages: number[] = [];
  genEntropyPercentages().reduce((sum, percentage, i) => {
    sum += percentage;
    cumulativePercentages[i] = sum;
    return sum;
  }, 0);

  return function (second: number) {
    if (second < 0 || second >= 60) {
      // OOPSIE, this should never happen
      console.trace("MINUTE ENTROPY OUT OF BOUNDS");
      setTimeout(() => {
        throw new Error("MINUTE ENTROPY OUT OF BOUNDS");
      }, 10);
    }
    return cumulativePercentages[second];
  };
}

import { useMemo } from "react";

import { Action } from "@/gql/graphql";

import { getFullName } from "@/lib/utils.ts";

import { Card, ImageWithErrorFallback, Text } from "@/app/components";
import { useActionMutation } from "@/app/hooks";
import { getCompanyStatus } from "@/app/misc/helpers";
import { UserImage } from "@/app/screens/dashboard/components/user-image.tsx";
import { AssignModalResponsive } from "@/app/screens/modal";
import { Badges, SocialMediaIcons } from "@/app/screens/opportunities/opportunity/components";
import { useSocialMediaIcons } from "@/app/screens/opportunities/opportunity/use-opportunity.tsx";
import modalStore from "@/app/stores/modal.store";

import { useOpportunityContext } from "../opportunity-context";

import { Assignees } from "./assign-users";
import { CompanyFlagToggle } from "./toggle-flags";

export const OpportunityHeader = () => {
  const {
    image,
    portCo,
    company,
    badges,
    tagline,
    trelloColumn,
    pcgLeads = [],
    signalAssignees,
    name = "Stealth Company",
    refresh,
  } = useOpportunityContext();

  const handleActionSuccess = async () => {
    if (refresh) {
      await refresh();
    }
    modalStore.close();
  };

  const socialMediaIcons = useSocialMediaIcons(company);
  const { inPipeline } = getCompanyStatus(company);
  const { onSubmit } = useActionMutation({ onSuccess: handleActionSuccess });

  const assignees = useMemo(
    () =>
      inPipeline
        ? signalAssignees
        : pcgLeads.map(({ trelloMemberId, ...member }) => ({ ...member, id: trelloMemberId })),
    [inPipeline, signalAssignees, pcgLeads],
  );

  const assigneIds: string[] = useMemo(() => {
    return assignees.map(({ id }) => id);
  }, [assignees]);

  return (
    <Card>
      <div className={"flex items-center space-x-3 lg:space-x-6"}>
        <ImageWithErrorFallback
          src={image}
          alt={name}
          iconClassName={"rounded-sm p-2 lg:p-5 bg-white"}
          className="flex size-20 rounded-md border border-neutral-300 lg:size-24 lg:rounded-sm"
        />
        <div className={"flex flex-1 flex-col justify-center space-y-1 lg:space-y-2"}>
          <div className="flex items-center justify-between gap-4">
            <div className="flex h-full items-center space-x-3">
              <Text
                text={name}
                type={"h4"}
                weight={"semibold"}
                noOfLines={2}
                className={"h-full shrink overflow-hidden text-ellipsis text-lg leading-5 lg:text-[33px] lg:leading-9"}
              />
              <SocialMediaIcons
                icons={socialMediaIcons}
                iconClassName={"w-4 h-4 lg:w-6 lg:h-6 lg:p-0.5 text-neutral hover:text-[#0072b1] cursor-pointer"}
                containerClassName={"space-x-3 lg:space-x-5 lg:mt-1"}
              />
            </div>
          </div>
          <div className={"flex"}>
            <Text
              text={portCo ? "Portfolio Company" : "Company"}
              weight={"light"}
              color={"text-neutral-700"}
              className="whitespace-nowrap text-sm lg:text-base"
            />
            <Text text={"•"} className={"mx-2 text-sm lg:text-base"} />
            <Text
              text={trelloColumn}
              weight={"light"}
              color={"text-neutral-700"}
              className="truncate text-sm lg:text-base"
            />
          </div>
          {company?.creator && false && (
            <div className="flex cursor-default items-center text-sm font-medium text-neutral-700">
              <span className="mr-2">Created By:</span>
              <UserImage user={company?.creator} />
              <span className="ml-1">{getFullName(company?.creator?.firstName, company?.creator?.lastName)}</span>
            </div>
          )}
        </div>
      </div>
      <div className="my-4 items-start md:flex md:space-x-20">
        {tagline && (
          <div className="mb-4 md:mb-0 md:max-w-[50%]">
            <Text type="subtitle" className="font-[500] text-neutral-900">
              Overview
            </Text>
            <Text text={tagline} type={"body"} color={"text-neutral-700"} className={"text-sm lg:text-base"} />
          </div>
        )}
        <Assignees
          title={!inPipeline ? "PCG Leads" : "Assign for Review"}
          assignees={assignees}
          inPipeline={inPipeline}
          handleRemoveAssignee={(assigneeId) =>
            onSubmit({
              entityId: company?.id,
              action: Action.ChangeAssignees,
              assignees: assigneIds.filter((id) => id !== assigneeId),
              successMessage: "Successfully removed assignee",
            })
          }
          handleAddAssignee={() => {
            modalStore.open(AssignModalResponsive, {
              props: {
                id: company?.id,
                title: inPipeline ? "Assign for Review" : "Assign Leads",
                action: inPipeline ? Action.Reassign : Action.ChangeAssignees,
                initialAssignees: assignees,
                onSuccess: handleActionSuccess,
              },
            });
          }}
        />
        {company && (
          <>
            <CompanyFlagToggle
              company={company}
              label={company.isOpCompany ? "Raising in <6 Months?" : "Share with Network?"}
              flag={company.isOpCompany ? "6_month_runway" : "sharing_allowed"}
              styles={{ toggleContainer: "flex" }}
            />
          </>
        )}
      </div>
      <Badges badges={badges} />
    </Card>
  );
};

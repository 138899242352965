import { useMemo } from "react";

import { isAfter, addMonths, parseISO } from "date-fns";

import { TrelloAttachment, TrelloComment } from "@/lib/service/trello.types";
import { cn } from "@/lib/utils";

import { Icon, Popover, TooltipInfo } from "@/app/components";

import { AttachmentList, CommentList } from "../opportunity/components/engagement";

import { NotAvailable } from "./not-available";

export interface EngagementOverviewProps {
  attachments?: TrelloAttachment[];
  comments?: TrelloComment[];
  showAll?: boolean;
  isSad?: boolean;
}

export const EngagementOverview = ({ attachments = [], comments = [], showAll, isSad }: EngagementOverviewProps) => {
  attachments = attachments || [];
  comments = comments || [];

  const lastEngagement = useMemo(
    () => [...attachments, ...comments].sort((a, b) => parseISO(a.date).getDate() - parseISO(b.date).getDate())[0],
    [attachments, comments],
  );

  const isEmpty = attachments.length === 0 && comments.length === 0;
  const isOld = lastEngagement && isAfter(new Date(), addMonths(parseISO(lastEngagement.date), 6));

  return isEmpty ? (
    <NotAvailable
      containerStyle="justify-start"
      message={showAll ? "N/A" : "No Data"}
      textStyle={cn("lg:text-[12px] 2xl:text-[14px]", showAll ? "text-black" : "text-neutral-300")}
      isSad={showAll && isSad}
      tooltipInfo={<TooltipInfo warning text="It looks like we haven’t engaged with this company" />}
      tooltipContainer="max-w-[200px] mt-2"
    />
  ) : (
    <div className="flex text-sm font-semibold text-neutral-500">
      <Popover
        childrenContent={
          attachments.length > 0 && (
            <div className="w-[200px]">
              <AttachmentList
                total={attachments.length}
                displayAttachments={[...attachments].splice(-4)}
                container="p-0"
                contentContainer="grid-cols-1 text-xs divide-y text-black gap-0 mb-2"
                attachmentContainer="py-2 border-0 rounded-none"
                size="md"
              />
              {isOld && <TooltipInfo info={isOld} text="Last attachment was added more than 6 months ago" />}
            </div>
          )
        }
      >
        <div className="mr-2 flex items-center">
          <Icon type="Attach" className="h-2 md:h-4 lg:h-3 2xl:h-4" />
          <span className="ml-1 text-xss leading-4 text-black md:text-sm lg:text-[12px] 2xl:text-[14px]">
            {attachments.length}
          </span>
        </div>
      </Popover>
      <Popover
        childrenContent={
          comments.length > 0 && (
            <div className="w-[200px]">
              <CommentList
                total={comments.length}
                displayComments={[...comments].splice(-2)}
                contentContainer="grid-cols-1 text-xs divide-y text-black gap-0 mb-2"
                commentContainer="py-2 border-0 rounded-none"
                size="md"
              />
              {isOld && <TooltipInfo info={isOld} text="Last comment was added more than 6 months ago" />}
            </div>
          )
        }
      >
        <div className="flex items-center">
          <Icon type="Comment" className="h-2 md:h-4 lg:h-3 2xl:h-4" />
          <span className="ml-1 text-xss leading-4 text-black md:text-sm lg:text-[12px] 2xl:text-[14px]">
            {comments.length}
          </span>
        </div>
      </Popover>
      {isOld && showAll && <Icon type="SandClock" className={cn("ml-0.5 inline-block lg:size-3 2xl:size-4")} />}
    </div>
  );
};

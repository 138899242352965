import { useState } from "react";

import { observer } from "mobx-react";

import { cn } from "@/lib/utils.ts";

import { Button, IconButton, NativeModal } from "@/app/components";
import { ActionsMenu } from "@/app/components/actions-menu";
import { ButtonProps } from "@/app/components/button/button.tsx";
import { OpportunityAction, useActionWrapper } from "@/app/hooks";
import { useOpportunityActions } from "@/app/hooks";
import modalStore from "@/app/stores/modal.store.tsx";

import { getAvailableOpportunityActionNames } from "../../../utils";

const disabledButtonProps: Partial<ButtonProps> = {
  variant: "secondary",
  disabled: true,
};

export const ActionButton = ({
  action,
  className,
  size = "md",
}: {
  action: OpportunityAction;
  className?: string;
  size?: "md" | "sm" | "xs";
}) => {
  const [loading, toggleLoading] = useState(false);

  const autoCloseModalActions = ["Hide", "Unhide", "Track", "Untrack"];

  const handleAction = async () => {
    toggleLoading(true);
    try {
      await action.action();
    } finally {
      if (autoCloseModalActions.includes(action.label)) modalStore.close();
      toggleLoading(false);
    }
  };

  return (
    <Button
      key={action.label}
      size={size}
      variant={action.variant}
      className={className}
      text={action.label}
      onClick={handleAction}
      loading={loading}
      {...(action.disabled ? disabledButtonProps : {})}
    />
  );
};

export const OpportunityActionsModal = observer(({ actions }: { actions: OpportunityAction[] }) => {
  return (
    <NativeModal isOpen={true} handleClose={modalStore.close} detent={"content-height"} bodyClassName="p-6">
      <div className="flex flex-col items-center gap-2">
        {actions.map((action) => (
          <ActionButton
            key={action.label}
            action={action}
            className={cn(
              "w-full rounded-sm border border-neutral-200 bg-white px-4 py-3 font-bold text-black active:bg-neutral-300",
              action.disabled && "pointer-events-none",
            )}
          />
        ))}
      </div>
    </NativeModal>
  );
});

export const OpportunityActionsMobile = ({ className, company }) => {
  const genericActions = useOpportunityActions({ company }) || {};
  const actionNames = getAvailableOpportunityActionNames({ company, defaultActions: ["follow", "hide"] });
  const actions = actionNames.map((name) => genericActions[name]);

  const handleClick = (e) => {
    e.stopPropagation();

    modalStore.open(OpportunityActionsModal, {
      props: {
        actions,
      },
    });
  };

  return <IconButton icon={"Add"} containerClassName={cn("size-6 !bg-transparent", className)} onClick={handleClick} />;
};

export const OpportunityActions = ({ className, company }) => {
  const { follow, ...actions } = useOpportunityActions({ company }) || {};
  const actionNames = getAvailableOpportunityActionNames({ company, defaultActions: ["hide"] });
  const options = actionNames.map((name) => actions[name]);
  const { loading, wrapAction } = useActionWrapper();

  return (
    <div data-cy="opportunity-actions" className={cn("flex gap-2", className)}>
      {follow && (
        <Button
          variant="secondary"
          data-cy="action_follow"
          size="sm"
          disabled={loading[follow.label]}
          loading={loading[follow.label]}
          onClick={() => wrapAction({ ...follow, isAsync: true })}
          className="min-w-1 px-3 text-sm font-[600]"
        >
          {follow?.label}
        </Button>
      )}
      <ActionsMenu
        title="More actions"
        icon={"Filter Chevron Down"}
        options={options}
        btnClassName="w-auto text-sm font-[600] min-w-1"
        iconClassName="w-auto ml-1"
        className={cn("w-[130px]")}
      />
    </div>
  );
};

/*

If a company is in Pipeline and it HAS a signal then the action buttons displayed should be:

1) Share
2) Snooze & Track
3) Convert to Prospect
4) Add Sentiment

If a company is in Pipeline and it has NO signal then the action buttons displayed should be:

1) Share
2) Convert to Prospect
3) Add Sentiment

If a company is in Prospects then the action buttons displayed should be:

1) Move to Track & Revisit
2) Move Stage
3) Add Sentiment

If a company is in Portfolio and not currently in Prospects then the action buttons displayed should be:
1) Convert to Prospect
2) Add Sentiment

If a company is in Portfolio and ALSO currently in Prospects then the action buttons displayed should be:
1) Remove Prospect
-> Remove from Prospect view. Will just show up in Portfolio
2) Move Stage
3) Add Sentiment

*/

import { useNavigate } from "react-router";

import { BreadCrumbs } from "@/app/components";

import { OpportunityActions, OpportunityActionsMobile } from "./actions/actions";

export const ActionsHeader = ({ company }) => {
  const navigate = useNavigate();
  return (
    <div data-cy="actions-header" className={"flex items-center justify-between"}>
      <BreadCrumbs
        title={"Back"}
        action={() => {
          navigate(-1);
        }}
        elementProps={{
          title: { className: "hidden md:block text-sm" },
          icon: {
            className: "w-4 h-4",
          },
        }}
      />
      <OpportunityActionsMobile className="lg:hidden" company={company} />
      <OpportunityActions className="hidden lg:flex" company={company} />
    </div>
  );
};

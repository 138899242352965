import { useEffect, useState } from "react";

import { cn } from "@/lib/utils.ts";

export const ProgressBarLoader = ({ loop = true }: { loop?: boolean }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = () =>
      setTimeout(() => {
        setProgress((prev) => {
          if (prev === 90) return 100;
          if (prev > 100) return 0;
          return (prev + 3) % 100;
        });
        interval();
      }, 100);
    if (loop) interval();
    return () => clearInterval(interval());
  }, [loop]);

  return (
    <div className="h-1 w-10 rounded-full bg-neutral-200 transition-all">
      <div className={cn("h-1 rounded-full bg-neutral-600")} style={{ width: progress + "%" }} />
    </div>
  );
};

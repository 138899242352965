import { differenceInMonths, format, formatDistance } from "date-fns";

import { BadgeType } from "@/types/badges.types.ts";

import { trelloColumnsList } from "./filters";

export function titleCase(str: string) {
  if (!str) return "";
  return str
    ?.toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
}

export function monthsAgo(date: Date | string, eventDate: Date | string = new Date()): number | null {
  try {
    const currentDate = new Date(date);
    const nextDate = new Date(eventDate);
    return Math.abs(differenceInMonths(currentDate, nextDate));
  } catch (e) {
    return null;
  }
}
export function normaliseDate(date?: Date | string, formatString = "dd LLL YYY") {
  if (!date) return "";
  try {
    const response = format(new Date(date), formatString);
    return response;
  } catch (e) {
    return null;
  }
}

export function timeAgo(date: Date | string) {
  return formatDistance(new Date(date), new Date(), { addSuffix: true });
}

export function combineTailwindStyles(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

export const defaultSignal: BadgeType = {
  id: "rule_name",
  title: `Added by <b>Iosif Boanca</b> via <b>Nazare VC &lt;nazare@localglobe.vc&gt;\r</b> on <b>02 Dec 2023</b>\n`,
  value: "Added via Iosif Boanca",
  group: "signal",
};

export const toOrdinalNum = (number: number) => {
  let selector: number;

  if (number <= 0) {
    selector = 4;
  } else if ((number > 3 && number < 21) || number % 10 > 3) {
    selector = 0;
  } else {
    selector = number % 10;
  }

  return number + ["th", "st", "nd", "rd", ""][selector];
};

export const getCompanyStatus = (company) => {
  const isPortfolio = company.isOpCompany;
  const listId = company?.companiesSources?.items?.find((item) => item.sourceType === "TrelloCard")?.trelloCardAsSource
    ?.list?.id;
  const isProspect = trelloColumnsList.map(({ value }) => value).some((item) => item === listId);
  const stageIsLegals = ["561fe5725d03faeba0a20b5e", "5d4bfb8eb30b468aa3ada3ff", "619b69465c658d509f7de1b0"].some(
    (item) => item === listId,
  );
  const hasSignal = company.signals?.items?.length > 0;
  const inPipeline = !isProspect && !isPortfolio;

  return {
    isPortfolio,
    isProspect,
    stageIsLegals,
    hasSignal,
    inPipeline,
  };
};

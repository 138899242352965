import { useNavigate } from "react-router";

import { LgCompany } from "@/gql/types.ts";

import { cn } from "@/lib/utils.ts";

import { CompanyCard } from "./company-card.tsx";

type WebCardContainerProps = {
  opportunities: (LgCompany & any)[];
  currentTab: string;
  className?: string;
};

export function CardList({ opportunities, className }: WebCardContainerProps) {
  const navigate = useNavigate();

  return (
    <ul
      data-cy="opportunity-list"
      role="list"
      className={cn("grid w-full grid-cols-1 gap-2 @6xl:grid-cols-2 @10xl:grid-cols-3", className)}
    >
      {opportunities.map((opportunity, index) => (
        <CompanyCard
          key={opportunity.id}
          company={opportunity}
          index={index}
          onClick={() => navigate(`/company/${opportunity.id}`)}
        />
      ))}
    </ul>
  );
}

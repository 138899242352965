import { Modal } from "@/app/components";

export const OpportunityMaximise = ({ children, maximised, setMaximised }) => {
  if (!maximised || !children) return null;
  return (
    <>
      <Modal
        isOpen={maximised}
        showX={false}
        handleClose={() => setMaximised(false)}
        containerClassName={"ease-in-out transition-all duration-500 z-30"}
        bodyContainerClassName={"h-dvh w-dvw"}
        bodyClassName={"min-h-[85dvh] min-w-[90dvw] p-0 rounded-[8px]"}
        bodyContentClassName={"px-0"}
      >
        {children}
      </Modal>
    </>
  );
};

import { useEffect } from "react";

import { format, isValid, parse } from "date-fns";
import FormType from "mobx-react-form";

import { useMembersMapped } from "@/lib/service/members";

import Form from "@/app/components/forms";
import { FormDatePicker, FormSelect } from "@/app/components/input";
import { Company } from "@/app/hooks";
import { useCompanyDetails } from "@/app/hooks/use-company-details";
import { formCategories } from "@/data/data";

interface RequestSentimentProps {
  company: Company;
  type: "sentiment" | "founderSentiment";
  form: FormType;
  founderId?: string;
}

const SelectFounder = ({ form, selectedFounder, render, type }) => {
  useEffect(() => {
    if (!form.$("founder").value && selectedFounder) {
      form.$("founder").value = selectedFounder;
    }
  }, [selectedFounder, form]);

  if (type === "founderSentiment") {
    return <Form.Field fieldId={"founder"} render={render} />;
  }

  return null;
};

export const SentimentForm = ({ form, company, type, founderId }: RequestSentimentProps) => {
  const { founders, loading } = useCompanyDetails(company.id);
  const { data: members } = useMembersMapped();

  const selectedFounder = founderId ? founders.find(({ id }) => founderId === id) : undefined;

  return (
    <section className="relative">
      <div className={"w-full"}>
        <div className={"flex w-full flex-col space-y-6"}>
          <Form.Root form={form}>
            <SelectFounder
              form={form}
              selectedFounder={selectedFounder}
              type={type}
              render={({ onChange }) => (
                <div className="flex flex-col">
                  <Form.Label />
                  <FormSelect
                    data-cy="select-sentiment-founder"
                    options={founders.map(({ name, id, image }) => ({
                      value: name,
                      label: name,
                      image: image ? "https://" + image : null,
                      id,
                    }))}
                    disabled={loading}
                    onChange={([value]) => onChange(value)}
                    placeholder={
                      loading
                        ? "Fetching Founder info..."
                        : founders.length > 0
                          ? "Please select a column..."
                          : "Missing founder info"
                    }
                    defaultOptions={
                      selectedFounder
                        ? [
                            {
                              value: selectedFounder.name,
                              label: selectedFounder.name,
                              image: selectedFounder.image ? "https://" + selectedFounder.image : null,
                              id: selectedFounder.id,
                            },
                          ]
                        : []
                    }
                  />
                  <Form.Error />
                </div>
              )}
            />
            <Form.Field
              fieldId={"category"}
              render={({ onChange, value }) => (
                <div className="my-3 flex flex-col">
                  <Form.Label />
                  <FormSelect
                    data-cy="select-sentiment-category"
                    defaultOptions={value ? [value] : undefined}
                    placeholder="Select category"
                    onChange={([value]) => onChange(value)}
                    options={formCategories}
                  />
                  <Form.Error />
                </div>
              )}
            />
            <Form.Field
              fieldId="assignees"
              render={({ onChange, value }) => (
                <div className={"mb-3 flex flex-col"}>
                  <Form.Label />
                  <FormSelect
                    data-cy="select-sentiment-assignee"
                    onChange={onChange}
                    defaultOptions={value}
                    placeholder="Select assignees"
                    multiSelect
                    options={members}
                  />
                  <Form.Error />
                </div>
              )}
            />
            <Form.Field
              fieldId="meetingDate"
              render={({ value, onChange }) => {
                return (
                  <div className={"flex flex-col"}>
                    <Form.Label />
                    <FormDatePicker
                      onChange={(date) => (date ? onChange(format(date, "yyyy-MM-dd")) : onChange(""))}
                      selected={
                        isValid(parse(value, "yyyy-MM-dd", new Date()))
                          ? parse(value, "yyyy-MM-dd", new Date())
                          : undefined
                      }
                      placeholder="Select date"
                      maxDate={new Date()}
                      enableYearNavigation
                    />
                    <Form.Error />
                  </div>
                );
              }}
            />
          </Form.Root>
        </div>
      </div>
    </section>
  );
};

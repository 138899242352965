import * as React from "react";
const SvgChf = (props) => /* @__PURE__ */ React.createElement("svg", { fill: "#000000", viewBox: "0 0 24 24", id: "swiss-franc", "data-name": "Flat Line", xmlns: "http://www.w3.org/2000/svg", className: "icon flat-line", color: "currentColor", ...props }, /* @__PURE__ */ React.createElement("g", { id: "SVGRepo_iconCarrier" }, /* @__PURE__ */ React.createElement("polyline", { id: "primary", points: "9 21 9 3 18 3", style: {
  fill: "none",
  stroke: "#000000",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2
} }), /* @__PURE__ */ React.createElement("path", { id: "primary-2", d: "M9,11h9M6,15h9", style: {
  stroke: "#000000",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2
} })));
export default SvgChf;

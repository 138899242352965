import React, { useContext } from "react";

import { gql, useQuery } from "@apollo/client";
import { GlyphDot } from "@visx/glyph";
import { LinearGradient } from "@visx/gradient";
import { getRandomNormal, getSeededRandom } from "@visx/mock-data";
import { PatternLines } from "@visx/pattern";
import { useParentSize } from "@visx/responsive";
import { scaleLinear } from "@visx/scale";
import { BoxPlot } from "@visx/stats";
import {
  AnimatedAxis,
  AnimatedGlyphSeries,
  AnimatedGrid,
  DataContext,
  GlyphProps,
  lightTheme,
  Tooltip,
  XYChart,
} from "@visx/xychart";
import cx from "classnames";
import { bin } from "d3-array";
import { curveCardinal, line } from "d3-shape";

import { capitalize, cn } from "@/lib/utils.ts";

import { colors } from "@/app/constants";

const accesors = {
  xAccessor: (data) => data.date,
  yAccessor: (data) => data.metric,
};

const seededRandom = getSeededRandom(0.1);
const randomNormal = getRandomNormal.source(getSeededRandom(0.789))(5, 1);

function ViolinPlot({
  left = 0,
  top = 0,
  className,
  data,
  width = 10,
  count = (d) => d.count,
  value = (d) => d.value,
  valueScale,
  horizontal,
  children,
  ...restProps
}) {
  const center = (horizontal ? top : left) + width / 2;
  const binCounts = data.map((bin) => count(bin));
  const widthScale = scaleLinear<number>({
    range: [0, width / 2],
    round: true,
    domain: [0, Math.max(...binCounts)],
  });

  let path = "";

  if (horizontal) {
    const topCurve = line()
      .x((d) => valueScale(value(d)) ?? 0)
      .y((d) => center - (widthScale(count(d)) ?? 0))
      .curve(curveCardinal);

    const bottomCurve = line()
      .x((d) => valueScale(value(d)) ?? 0)
      .y((d) => center + (widthScale(count(d)) ?? 0))
      .curve(curveCardinal);

    const topCurvePath = topCurve(data) || "";
    const bottomCurvePath = bottomCurve([...data].reverse()) || "";
    path = `${topCurvePath} ${bottomCurvePath.replace("M", "L")} Z`;
  } else {
    const rightCurve = line()
      .x((d) => center + (widthScale(count(d)) ?? 0))
      .y((d) => valueScale(value(d)) ?? 0)
      .curve(curveCardinal);

    const leftCurve = line()
      .x((d) => center - (widthScale(count(d)) ?? 0))
      .y((d) => valueScale(value(d)) ?? 0)
      .curve(curveCardinal);

    const rightCurvePath = rightCurve(data) || "";
    const leftCurvePath = leftCurve([...data].reverse()) || "";
    path = `${rightCurvePath} ${leftCurvePath.replace("M", "L")} Z`;
  }
  if (children) return <>{children({ path })}</>;
  return <path className={cx("visx-violin", className)} d={path} {...restProps} />;
}

const renderGlyph = ({
  x,
  y,
  datum,
  size,
  onPointerMove,
  onPointerOut,
  onPointerUp,
}: GlyphProps<{ x: string; y: number }>) => {
  const handlers = { onPointerMove, onPointerOut, onPointerUp };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { xScale, yScale, innerWidth } = useContext(DataContext) || {};
  const width = 22;
  const violinWidth = width * 3;

  let yScaleCopy = function (d) {
    return yScale(d) - yScale(datum.metric);
  };

  yScaleCopy = Object.assign(yScaleCopy, { range: yScale.range });

  return (
    <>
      {/*<ViolinPlot*/}
      {/*  // left={xScale(datum.date)}*/}
      {/*  left={-width / 2}*/}
      {/*  data={datum.bins}*/}
      {/*  valueScale={(d) => yScale(d) - yScale(datum.metric)}*/}
      {/*  width={width}*/}
      {/*  fill={"rgba(233,54,107,0.2)"}*/}
      {/*/>*/}

      <BoxPlot
        min={datum.boxPlot.min}
        max={datum.boxPlot.max}
        left={-width / 2}
        firstQuartile={datum.boxPlot.q25}
        thirdQuartile={datum.boxPlot.q75}
        median={datum.boxPlot.q50}
        boxWidth={width}
        fill="url(#area-gradient)"
        fillOpacity={0.2}
        stroke={colors.primary.DEFAULT}
        strokeWidth={1}
        valueScale={yScaleCopy}
      />
      <ViolinPlot
        left={-violinWidth / 2}
        data={datum.bins}
        valueScale={yScaleCopy}
        width={violinWidth}
        fillOpacity={0.1}
        fill="url(#area-gradient)"
      />
      <GlyphDot
        left={x}
        top={y}
        fill="url(#area-gradient)"
        stroke={"white"}
        strokeWidth={1}
        fillOpacity={0.8}
        r={size / 2 + 1}
        {...handlers}
      />
    </>
  );
};

function SentimentChart({ data, analytics, width, height }) {
  return (
    <XYChart
      xScale={{ type: "band" }}
      yScale={{ type: "linear", domain: [analytics.min - 0.5, analytics.max + 0.5], zero: false, round: true }}
      theme={lightTheme}
      width={width}
      margin={{ top: 20, right: 20, bottom: 20, left: 30 }}
      height={height}
    >
      <LinearGradient id="area-gradient" from={colors.secondary.DEFAULT} to={colors.primary.DEFAULT} />
      <PatternLines
        id="violinLines"
        height={3}
        width={3}
        stroke="#E9366B"
        strokeWidth={1}
        // fill="rgba(0,0,0,0.3)"
        orientation={["horizontal"]}
      />
      <AnimatedGrid
        key={`grid-xy`} // force animate on update
        rows={false}
        columns={true}
        strokeDasharray={"8"}
        lineStyle={{ stroke: colors.neutral[400], strokeWidth: 1 }}
      />
      <AnimatedAxis
        key={`time-axis`}
        orientation="bottom"
        axisLineClassName={"stroke-neutral-300"}
        strokeWidth={1}
        strokeDasharray={"8"}
        hideTicks={true}
        tickLabelProps={{
          className: cn("text-xss font-semibold text-neutral-300"),
        }}
      />
      <AnimatedAxis
        key={`right-axis`}
        axisLineClassName={"stroke-neutral-300"}
        strokeWidth={1}
        strokeDasharray={"8"}
        hideTicks={true}
        tickLabelProps={{ className: cn("text-xss font-semibold text-neutral-300") }}
        orientation={"left"}
      />
      {/*{data.map((datum) => (*/}
      {/*  <ViolinGlyph datum={datum} />*/}
      {/*))}*/}

      {/*<AnimatedLineSeries*/}
      {/*  dataKey="line"*/}
      {/*  strokeWidth={3.5}*/}
      {/*  stroke="url(#area-gradient)"*/}
      {/*  fillOpacity={0.9}*/}
      {/*  data={data}*/}
      {/*  {...accesors}*/}
      {/*  curve={curveMonotoneX}*/}
      {/*/>*/}
      <AnimatedGlyphSeries renderGlyph={renderGlyph} dataKey="line" data={data} {...accesors} />

      <Tooltip
        applyPositionStyle={true}
        snapTooltipToDatumX
        snapTooltipToDatumY
        showHorizontalCrosshair
        showSeriesGlyphs
        horizontalCrosshairStyle={{ strokeDasharray: "8" }}
        renderGlyph={(props) => (
          <>
            <GlyphDot {...props} r={4} fill="url(#area-gradient)" stroke={"white"} />
          </>
        )}
        renderTooltip={({ tooltipData }) => {
          return (
            <div className={"flex cursor-pointer flex-col items-center justify-center p-2"}>
              <p className={"text-xss font-medium text-neutral-700"}>{`${tooltipData?.nearestDatum?.datum?.date}`}</p>
              <p className="my-2 text-[16px] font-semibold text-neutral-900">
                {accesors.yAccessor(tooltipData?.nearestDatum?.datum)?.toFixed(2)}
              </p>
              <p className={"text-xss font-medium text-neutral-700"}>
                Max: {tooltipData?.nearestDatum?.datum?.boxPlot?.max.toFixed(2)}
              </p>
              <p className={"text-xss font-medium text-neutral-700"}>
                Q3: {tooltipData?.nearestDatum?.datum?.boxPlot?.q75.toFixed(2)}
              </p>
              <p className={"text-xss font-medium text-neutral-700"}>
                Median: {tooltipData?.nearestDatum?.datum?.boxPlot?.q50.toFixed(2)}
              </p>
              <p className={"text-xss font-medium text-neutral-700"}>
                Q1: {tooltipData?.nearestDatum?.datum?.boxPlot?.q25.toFixed(2)}
              </p>
              <p className={"text-xss font-medium text-neutral-700"}>
                Min: {tooltipData?.nearestDatum?.datum?.boxPlot?.min.toFixed(2)}
              </p>
            </div>
          );
        }}
      />
    </XYChart>
  );
}

type AttrTypes = ["lastSentiment", "firstSentiment", "last3Sentiment", "first3Sentiment", "allSentiment"];

function generateBoxPlotQuery(sentimentField: AttrTypes) {
  return gql`
    query BoxPlot($fundName: String!) {
      lgCompanySentimentAnalytics(
        filter: {
          company: {
            isOpCompany: { equalTo: true }
            srcCompaniesByCompanyId: {
              some: {
                opsCompanyAsSource: { opsCompanyfundsByCompanyId: { some: { fund: { name: { equalTo: $fundName } } } } }
              }
            }
          }
        }
      ) {
        aggregates {
          jsonbBoxplot {
            sentiment: ${sentimentField}
          }
        }
      }
    }
  `;
}

export function SentimentBoxes({ sentiment, benchmarkAttribute, fundSelected }) {
  const {
    data: boxPlotData,
    loading,
    error,
  } = useQuery(generateBoxPlotQuery(benchmarkAttribute), { variables: { fundName: fundSelected } });

  const boxPlots = boxPlotData?.lgCompanySentimentAnalytics?.aggregates?.jsonbBoxplot?.sentiment;

  const data = Object.keys(boxPlots || {}).map((item) => {
    const domain: [number, number] = [boxPlots[item].min, boxPlots[item].max];
    let bins = bin()
      .domain(domain)
      .thresholds(6)(boxPlots[item].bins)
      .map((bin) => ({
        value: bin.x0 + 0.001,
        count: bin.length,
      }));

    bins = [{ value: domain[0], count: 0.001 }, ...bins, { value: domain[1], count: 0.001 }];

    return {
      metric: sentiment?.[benchmarkAttribute]?.[item],
      id: item,
      form: item,
      date: capitalize(item),
      boxPlot: {
        min: boxPlots[item].min,
        q25: boxPlots[item].q1,
        q50: boxPlots[item].median,
        q75: boxPlots[item].q3,
        max: boxPlots[item].max,
      },
      bins,
    };
  });

  const analytics = {
    min: Math.min(...data.map((item) => item.boxPlot.min)),
    max: Math.max(...data.map((item) => item.boxPlot.max)),
  };

  const { parentRef, width, height } = useParentSize({
    debounceTime: 250,
  });

  return (
    <div ref={parentRef} className={"h-full flex-1 basis-1/3 lg:min-h-[400px]"}>
      <SentimentChart data={data} analytics={analytics} width={width} height={height} />
    </div>
  );
}

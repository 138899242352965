import { Button, CompanyImg, Icon } from "@/app/components";
import { Combobox } from "@headlessui/react";
import Highlighter from "react-highlight-words";
import { SearchRef, useSearchRef } from "@/app/hooks/use-search-ref";
import { SearchDetails } from ".";
import { useCallback, useMemo, useState } from "react";
import { START_QUERY_AFTER, Company } from "@/app/hooks";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface SearchResultProps {
  searchTerm: string;
  className?: string;
  containerClassName?: string;
  loading?: boolean;
  queryRef: SearchRef;
  handleNavigation?: (path: string) => void;
  handleSelect?: (value: Company) => void;
  handleAddNewOpportunity?: () => void;
}

export const SearchResults = ({
  queryRef,
  searchTerm,
  className = "",
  containerClassName = "",
  loading,
  handleNavigation,
  handleSelect,
  handleAddNewOpportunity,
}: SearchResultProps) => {
  const { result } = useSearchRef(queryRef);
  const [selected, setSelected] = useState<Company>();
  const showDetails = useMemo(() => !handleSelect, [handleSelect]);

  const onSelect = useCallback(
    (value) => {
      if (handleSelect) handleSelect(value);

      if (!handleSelect) setSelected(value);
    },
    [handleSelect],
  );

  const isEmpty = searchTerm.length > START_QUERY_AFTER && result.length === 0 && !loading;

  if (isEmpty) {
    return (
      <div className="px-6 py-14 text-center text-sm sm:px-14">
        <p className="mt-4 text-2xl font-semibold text-black wrapword">Couldn't find &nbsp; {`"${searchTerm}"`}</p>
        <p className="mt-2 text-base text-black">Try searching again using a different spelling &nbsp; or</p>
        <Button
          text="Add New Company"
          variant="outline-black"
          size="md"
          className="mx-auto my-4 rounded-md"
          iconRight="Add"
          onClick={handleAddNewOpportunity}
          data-cy="global-search-add-new-company"
        />
      </div>
    );
  }

  return (
    <div className={`h-full w-full ${containerClassName}`}>
      <Combobox onChange={onSelect}>
        {result.length > 0 && (
          <Combobox.Options
            static
            className={`h-full max-h-[500px] transform-gpu scroll-py-3 overflow-y-auto border-r border-neutral-200 p-3 ${className}`}
          >
            {result.map((item) => (
              <Combobox.Option
                key={item.id}
                value={item}
                className={({ active, selected }) =>
                  classNames("flex cursor-pointer select-none rounded-xs p-3", (active || selected) && "bg-gray-100")
                }
              >
                {({ active, selected }) => (
                  <div className="flex w-full items-center" data-cy="search-results-item">
                    <div className={classNames("flex h-10 w-10 flex-none items-center justify-center")}>
                      <CompanyImg src={item.image} />
                    </div>
                    <div className="ml-4">
                      <p
                        className={classNames(
                          "text-left text-sm font-medium",
                          active ? "text-gray-900" : "text-gray-700",
                        )}
                      >
                        <Highlighter
                          textToHighlight={item.name}
                          searchWords={[searchTerm]}
                          highlightClassName="font-bold bg-transparent"
                        />
                      </p>
                    </div>
                    {(active || selected) && (
                      <Icon type="ArrowRight" width={24} height={24} className="ml-auto text-neutral-400" />
                    )}
                  </div>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
      {showDetails && selected && handleNavigation && (
        <SearchDetails id={selected.id} handleNavigation={handleNavigation} className="col-span-2" />
      )}
    </div>
  );
};

import { ChevronLeft, X } from "lucide-react";
import { useCallback, useMemo, useState } from "react";

type PropsNode = {
  title: string;
  nodes?: PropsNode[];
  extraAttrs?: Record<string, string>;
  closeOnClick?: boolean;
  selectedText?: string;
};

type SettingsProps = {
  node: PropsNode;
  onClose: () => void;
};

type Node = {
  title: string;
  key: string;
  nodes?: Node[];
  parent?: Node;
  extraAttrs?: Record<string, string>
  closeOnClick?: boolean;
  selectedText?: string;
};

const ITEM_KEY_ATTR = "data-setings-menu-item";
export function Settings(props: SettingsProps) {
  const [node, dict] = useMemo(() => {
    const node = nodeFromProps(props.node);
    const dict = mapFromNode(node);
    return [node, dict];
  }, [props.node]);
  const [item, setItem] = useState(node);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof Element) {
      const itemClickedKey = event.target?.closest(`[${ITEM_KEY_ATTR}]`)?.getAttribute(ITEM_KEY_ATTR);
      if (itemClickedKey) {
        event.preventDefault();
        const itemClicked = dict.get(itemClickedKey);
        // NOTE: should never happen !?
        if (!itemClicked) return;

        // NOTE: only go deeper if there're children
        if (!itemClicked.nodes?.length) return;
        setItem(itemClicked);
      }
    }
  }, []);
  
  const handleOuterClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof Element) {
      if (event.target.hasAttribute("data-settings-menu-outer")) {
        props.onClose();
      }

      if (event.target.hasAttribute("data-close-on-click")) {
        props.onClose();
      }
    }


  }, [props.onClose]);
  return (
    <div onClick={handleOuterClick} data-settings-menu-outer className="font-barlow bg-black/50 fixed w-screen h-screen flex items-end @2xl:items-center justify-center top-0 left-0 z-50">
      <div className="max-w-[460px] w-full max-h-screen">
        <div className="w-full bg-white rounded-md shadow-md divide-y" onClick={handleClick}>
          <div className="p-4 text-[18px] p-4 font-medium text-black flex items-center">
            {item.parent ? (
              <span
                className="pointer flex items-center transform translate-y-[1px]"
                {...{ [ITEM_KEY_ATTR]: item.parent.key }}
              >
                <ChevronLeft className="inline" />
              </span>
            ) : null}
            {item.title}
            <span className="ml-auto" onClick={props.onClose}>
              <X />
            </span>
          </div>
          <div className="divide-y">{item.nodes?.map((e) => <SettingsItem item={e as Node} />)}</div>
        </div>
      </div>
    </div>
  );
}

type SettingsItemProps = {
  item: Node;
};

function SettingsItem(props: SettingsItemProps) {
  const { item } = props;
  
  const closeOnClickAttr = item.closeOnClick ? {"data-close-on-click": ""} : {};
  return (
    <div
      key={item.key}
      {...{ ...item.extraAttrs, ...closeOnClickAttr, [ITEM_KEY_ATTR]: item.key }}
      className="p-4 text-[14px] font-medium text-black flex flex-row"
    >
      {item.title}
      <span className="ml-auto text-neutral font-normal">{item.selectedText}</span>
    </div>
  );
}

function nodeFromProps(pnode: PropsNode, parent?: Node): Node {
  const node: Node = {
    title: pnode.title,
    key: `${parent?.key || "r:"}:${pnode.title}:`,
    extraAttrs: pnode.extraAttrs,
    parent,
    closeOnClick: pnode.closeOnClick,
    selectedText: pnode.selectedText
  };

  if (pnode.nodes) {
    node.nodes = pnode.nodes.map((e) => {
      const n = nodeFromProps(e, node);
      return n;
    });
  }

  return node;
}

function mapFromNode(root: Node): Map<string, Node> {
  const map = new Map();
  const stack = [root];

  while (stack.length > 0) {
    const node = stack.pop() as Node;
    map.set(node.key, node);
    if (node.nodes) {
      stack.push(...node.nodes);
    }
  }

  return map;
}

import { action, makeObservable, observable } from "mobx";
import { hydrateStore, isHydrated, makePersistable } from "mobx-persist-store";

import { GetCompaniesQuery } from "@/gql/graphql.ts";

import { TabBarProps } from "@/app/components/select-controls/tab-bar.tsx";

export type OptionsType = {
  id?: string;
  value: string | number | Record<string, string | number>;
  label: string;
  image?: string | null;
  disabled?: string;
};

type ValueOf<T> = T[keyof T];

export type ModalAction = "add" | "snooze" | "share" | "accept" | "mobileInfo" | "sentiment_details" | "add_trello";

type SnoozeAction = {
  snooze: {
    reason?: Array<OptionsType> | Array<never>;
    period?: Array<OptionsType> | Array<never>;
  };
};

type AddOpportunity = {
  addOpportunity: {
    name: string;
    website?: string;
    location?: string;
    description?: string;
    industries?: string[];
    lastRound?: string;
    targetFund?: string;
  };
};

type ShareAction = {
  share: {
    with?: Array<OptionsType> | Array<never>;
    note?: string;
  };
};

type MobileInfo = {
  mobileInfo: {
    title?: string;
    info?: string;
  };
};

export type ModalValues = SnoozeAction | ShareAction | MobileInfo | AddOpportunity;

type ModalState = Record<string, ModalValues>;

export enum OpportunitiesTab {
  portfolio = "portfolio",
  prospects = "prospects",
  pipeline = "pipeline",
  investments = "investments",
}

export type OpportunitiesTabType = keyof typeof OpportunitiesTab;

export type OpportunitiesState = {
  currentOpportunity: string;
  listType: "card" | "list";
  currentTab: { path: OpportunitiesTabType; quickView: string };
  extra?: Record<string, unknown>;
  totalOpportunityCount: number;
  opportunitiesLength: number;
};

class OpportunitiesStore {
  static defaultState: OpportunitiesState = {
    extra: {},
    listType: "card",
    currentOpportunity: "",
    totalOpportunityCount: 0,
    opportunitiesLength: 0,
    currentTab: { path: "pipeline", quickView: "all" },
  };
  public isHydrated = false;
  offset = { y: 0, x: 0 };

  state: OpportunitiesState = structuredClone(OpportunitiesStore.defaultState);

  modalState: ModalState = {};

  constructor() {
    makeObservable(this, {
      state: observable,
      modalState: observable,
      setState: action,
      setModalState: action,
      setOpportunities: action,
    });
    makePersistable(
      this,
      {
        stringify: true,
        debugMode: import.meta.env.VITE_MOBX_DEBUG === "true",
        name: "opportunitiesStore",
        storage: window.localStorage,
        properties: ["state"],
      },
      { fireImmediately: true },
    ).catch((e) => console.error("Error hydrating store", e));

    this.waitForHydration();
  }

  async waitForHydration() {
    if (isHydrated(this)) {
      this.isHydrated = true;
      return;
    }

    await hydrateStore(this);
    this.isHydrated = true;
  }

  setState(newState: Partial<OpportunitiesState>) {
    this.state = { ...this.state, ...newState };
  }

  getModalState(action: ModalAction, key?: keyof ValueOf<ModalValues>) {
    if (!key) return this.modalState[this.state.currentOpportunity]?.[action];
    return this.modalState[this.state.currentOpportunity]?.[action]?.[key];
  }

  setModalState(action: ModalAction, newState: Record<ValueOf<ModalValues>, OptionsType[] | string>) {
    const id = this.state.currentOpportunity;
    if (!id) return;

    this.modalState = {
      ...this.modalState,
      [id]: {
        ...(this.modalState?.[id] || {}),
        [action]: {
          ...(this.modalState?.[id]?.[action] || {}),
          ...newState,
        },
      },
    };
  }

  setOpportunities(opportunities?: GetCompaniesQuery) {
    this.state.totalOpportunityCount = opportunities?.lgCompanies?.totalCount ?? 0;
    this.state.opportunitiesLength = opportunities?.lgCompanies?.edges.length ?? 0;
  }

  opportunityTabs: Record<OpportunitiesTabType, TabBarProps["tabs"]> = {
    pipeline: [
      { label: "Previously Met", value: "previously_met" },
      { label: "Basecamp", value: "basecamp" },
      { label: "All", value: "all" },
    ],
    prospects: [
      { label: "My Prospects", value: "my_prospects" },
      { label: "Test Chemistry / EV Filter", value: "test_chemistry" },
      { label: "Developing Conviction", value: "developing_conviction" },
      { label: "Investment Offer", value: "making_investment_offer" },
      { label: "Legals", value: "legals" },
      { label: "All", value: "all" },
    ],
    portfolio: [
      { label: "I'm Leading", value: "led_by" },
      { label: "I'm Supporting", value: "supported_by" },
      // { label: "Value drivers", value: "value_drivers" },
      { label: "Focus Companies", value: "focus_companies" },
      // { label: "Liquidity", value: "liquidity" },
      { label: "All", value: "all" },
    ],
    investments: [
      { label: "Value drivers", value: "value_drivers" },
      { label: "Focus Companies", value: "focus_companies" },
      { label: "Liquidity", value: "liquidity" },
      { label: "LG12", value: "lg12" },
      { label: "LG11", value: "lg11" },
      { label: "LGX", value: "lgx" },
      { label: "LG8", value: "lg8" },
      { label: "LG7", value: "lg7" },
      { label: "LT3", value: "lt3" },
      { label: "LT2", value: "lt2" },
      { label: "LT1", value: "lt1" },
      { label: "SF1", value: "sf1" },
      { label: "All", value: "all" },
    ],
  };

  get opportunityTab() {
    return this.opportunityTabs[this.state.currentTab.path];
  }

  clearAll() {
    this.state = structuredClone(OpportunitiesStore.defaultState);
    this.modalState = {};
  }
}

export default new OpportunitiesStore();

import { useBreakpoints } from "@/lib/hooks";
import { cn, getInitials, windowOpen } from "@/lib/utils.ts";

import { Card, Icon, ImageWithErrorProfile, Text } from "@/app/components";
import { sectionBorderRadiusClassName } from "@/app/screens/opportunities/opportunity/components/section.config.tsx";

import { FounderSentiment } from "./founder-sentiment.tsx";
import { FounderDisplayData, FounderFromTeam } from "./founders.types.ts";

type FounderProps = {
  founder: FounderDisplayData;
};

const isFounderFromTeamData = (founder: FounderDisplayData): founder is FounderFromTeam => {
  return "id" in founder;
};

export const Founder = ({ founder }: FounderProps) => {
  const { isBigTablet } = useBreakpoints();

  const isFullFounderData = isFounderFromTeamData(founder);

  return (
    <Card className={cn("group flex flex-1 flex-col justify-between gap-2 lg:h-36 lg:flex-row lg:gap-0 lg:p-0")}>
      <div className="flex items-center justify-start space-x-3 lg:space-x-6">
        <ImageWithErrorProfile
          className={cn(
            "size-16 rounded-sm bg-neutral-300",
            "lg:size-36",
            `lg:rounded-l-sm lg:rounded-r-none`,
          )}
          labelClassName={"text-white text-[32px]"}
          src={isFullFounderData && founder.image ? "https://" + founder.image : null}
          alt={founder.name ? getInitials(founder.name) : "N/A"}
        />
        <div className="h-full items-center justify-between">
          <div className="flex h-full flex-col justify-center space-y-2">
            <Text text={founder.name} type={"subtitle"} />
            {isFullFounderData && !!founder.titles?.length && (
              <Text
                text={founder.titles.reduce((acc, item, idx) => acc + (idx ? " • " : "") + item.name, "")}
                type={"message"}
                weight={"medium"}
                color={"text-neutral-600"}
                className={"w-fit text-nowrap rounded-xs"}
              />
            )}
            {isBigTablet && founder?.linkedinUrl && (
              <div
                className={"flex cursor-pointer select-none items-center pt-2"}
                onClick={() => windowOpen(founder.linkedinUrl!, "_blank")}
              >
                <Icon type={"Linkedin"} className="mr-1 size-4 text-linkedin" />
                <Text
                  text={(founder.linkedinUrl.split("/in/").at(-1) ?? "").replace("/", "")}
                  color={"text-neutral-600"}
                  className={"text-[14px] underline"}
                />
              </div>
            )}
          </div>
        </div>

        {!isBigTablet && founder?.linkedinUrl && (
          <div
            className="!ml-auto flex size-7 items-center self-baseline rounded-sm p-1"
            onClick={() => windowOpen(founder.linkedinUrl!, "_blank")}
          >
            <Icon type={"Linkedin"} className="size-5 text-linkedin" />
          </div>
        )}
      </div>
      {isFullFounderData && <FounderSentiment founder={founder} />}
    </Card>
  );
};

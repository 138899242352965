import React from "react";

import { DateTime } from "luxon";
import { observer } from "mobx-react";

import { extractFormCategory } from "@/lib/utils.ts";

import { ModalHeader, ModalWebFrame } from "@/app/screens/modal/components";
import { opportunitiesStore } from "@/app/screens/opportunities";
import { SentimentAnswer } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-modal-content.tsx";

const MODAL_STATE = "sentiment_details";

export const SentimentDialogWeb = observer(() => {
  const form = opportunitiesStore.getModalState(MODAL_STATE, "form");

  return (
    <ModalWebFrame>
      <div className={"h-[70dvh] w-full overflow-auto"}>
        <ModalHeader
          title={extractFormCategory(form)}
          subtitle={`${form?.createdAt && DateTime.fromISO(form.createdAt).toLocaleString()} - ${form?.companyName}`}
        />
        <div className={"flex w-full flex-col space-y-6"}>
          {form?.fields
            ?.filter((field) => field?.group_id !== "name" && field?.group_id !== "form_category")
            .map((field, idx) => <SentimentAnswer key={`${field?.field_hash}_${idx}`} form={form} field={field} />)}
        </div>
      </div>
    </ModalWebFrame>
  );
});

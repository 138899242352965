import { cn } from "@/lib/utils.ts";

import { Icon, Text } from "@/app/components";

type BreadCrumbsProps = {
  title: string;
  action: () => void;
  className?: string;
  elementProps?: {
    icon?: { className?: string };
    title?: { className?: string };
  };
};

export function BreadCrumbs({ title, action, className, elementProps }: BreadCrumbsProps) {
  return (
    <div
      data-cy="bread-crumbs"
      className={cn(`hover:group flex w-fit cursor-pointer items-center`, className)}
      onClick={action}
    >
      <Icon type={"ArrowLeft"} className={cn("h-4.5 group-hover:text-neutral-600", elementProps?.icon?.className)} />
      <Text
        text={title}
        className={cn("ml-2 group-hover:text-neutral-600", elementProps?.title?.className)}
        type={"subtitle"}
      />
    </div>
  );
}

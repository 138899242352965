import { BreadCrumbs } from "@/app/components";
import { gql, useQuery } from "@apollo/client";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

const networkContactQuery = gql`
  query GetNetworkContact($contactEmail: String) {
    contact: getNetworkContact(contactEmail: $contactEmail) {
      email
      internalContacts
      sent
      received
      lastSent
      lastReceived
      connStrength
      tags
      names
      domain
      type
    }

    connections: getNetworkContactConnections(contactEmail: $contactEmail) {
      nodes {
        timestamp
        connectionEmail
        direction
        type
      }
    }
  }
`;

const numberFormat = Intl.NumberFormat("en-GB");

export function NetworkContact() {
  const params = useParams();
  const { data, loading } = useQuery(networkContactQuery, { variables: { contactEmail: params.email } });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const goBack = useCallback(() => {
    navigate(
      `/network?q=${searchParams.get("fq")}&sort=${searchParams.get("fsort")}&filter=${searchParams.get("ffilter")}`
    );
  }, []);
  if (!data?.contact) return null;

  const totalVolume = +data.contact.sent + +data.contact.received;
  const totalVolumeText =
    data.connections.nodes.length >= 1000
      ? `1,000 out of ${numberFormat.format(totalVolume)}`
      : `${numberFormat.format(data.connections.nodes.length)}`;

  const pluralRule = new Intl.PluralRules("en-GB");
  const messageForms = {
    one: "message",
    other: "messages",
  };
  return (
    <div className="flex @container flex-col font-barlow w-full max-w-[1600px] p-4 lg:p-8 mt-[70px] lg:mt-0 max-w-[100%] h-width gap-4">
      <div className="flex flex-col gap-4 px-4 lg:px-0">
        <BreadCrumbs
          title="Back"
          action={goBack}
          elementProps={{
            title: { className: "block text-sm" },
            icon: {
              className: "w-4 h-4",
            },
          }}
        />
      </div>

      <div className="rounded-md bg-white p-4">
        <div className="overflow-hidden text-ellipsis line-clamp-1  text-[33px] font-bold">{data.contact.email}</div>
        <div className="py-2">
          <div className="text-[18px] font-[500] text-neutral-900">Volume</div>
          <div className="mb-1 text-[14px] text-neutral">
            {+data.contact.received ? (
              <>
                We sent <span className="text-black font-medium">{numberFormat.format(+data.contact.received)}</span>{" "}
                {messageForms[pluralRule.select(+data.contact.received)]} to{" "}
                <span className="font-medium text-black">{data.contact.email}</span> most recent{" "}
                <DateChip inline value={data.contact.lastReceived} />
              </>
            ) : (
              <>
                We didn't send any emails to <span className="font-medium text-black">{data.contact.email}</span>
              </>
            )}
          </div>
          <div className="text-[14px] text-neutral">
            {+data.contact.sent ? (
              <>
                We received <span className="text-black font-medium">{numberFormat.format(+data.contact.sent)}</span>{" "}
                {messageForms[pluralRule.select(+data.contact.sent)]} from{" "}
                <span className="font-medium text-black">{data.contact.email}</span> most recent{" "}
                <DateChip inline value={data.contact.lastSent} />
              </>
            ) : (
              <>
                We didn't send any emails to <span className="font-medium text-black">{data.contact.email}</span>
              </>
            )}
          </div>
        </div>

        <div className="py-2">
          <div className="text-[18px] font-[500] text-neutral-900">Known Names</div>
          <div className="text-[14px] text-neutral overflow-hidden text-ellipsis line-clamp-3">
            {data.contact.names?.join(", ")}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 lg:items-start @4xl:flex-row-reverse lg:gap-6">
        <div className="rounded-md bg-white p-4 w-full @4xl:w-1/2">
          <div className="p-2 text-[14px]">
            <div className="text-black font-medium">
              Connections <span className="text-neutral font-normal">{data.contact.internalContacts.length}</span>
            </div>
          </div>
          <NetworkContactConnectionsCompact connections={data.contact.internalContacts} />
        </div>

        <div className="rounded-md bg-white p-4 w-full @4xl:w-1/2">
          <div className="p-2 text-[14px]">
            <div className="text-black font-medium">
              Recent Communication <span className="text-neutral font-normal">{totalVolumeText}</span>
            </div>
          </div>
          <NetworkContactConnections contactEmail={data.contact.email} connections={data.connections.nodes} />
        </div>
      </div>
    </div>
  );
}

export function NetworkContactConnectionsCompact(props) {
  return (
    <table className="divide-y full-w">
      <thead className="sticky top-[50px] lg:top-0 z-9">
        <tr className="text-[14px] leading-none bg-white">
          <th></th>
          <th className="p-2 text-right w-[1%]">Volume</th>
          <th className="p-2 w-[1%]">Most Recent</th>
        </tr>
      </thead>
      <tbody className="divide-y">
        {props.connections.map((connection) => {
          return (
            <tr className="text-[14px] leading-none text-[14px]">
              <td className="py-1 p-2 font-medium text-black bg-white whiespace-nowrap overflow-hidden text-ellipsis line-clamp-1 ">
                {connection.internal_email}
              </td>
              <td className="py-1 p-2 text-neutral w-[1%] bg-white text-right">
                {numberFormat.format(connection.count)}
              </td>
              <td className="py-1 p-2 text-neutral bg-white whiespace-nowrap text-right">
                <DateChip value={`${connection.last_communication}Z`} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export function NetworkContactConnections(props) {
  const { connections, contactEmail } = props;
  return (
    <div>
      <div className="sticky top-[50px] lg:top-0 flex flex-row text-[14px]  text-neutral font-medium bg-white">
        <div className="p-2 w-1/2 overflow-hidden text-ellipsis line-clamp-2 ">
          <span className="text-black">{contactEmail}</span>{" "}
          <span className="whiespace-nowrap inline-block">sent to us</span>
        </div>
        <div className="p-2 w-1/2 text-right overflow-hidden text-ellipsis line-clamp-2 ">
          <span className="whiespace-nowrap inline-block">We sent to</span>{" "}
          <span className="text-black whiespace-nowrap">{contactEmail}</span>
        </div>
      </div>

      {connections.map((connection) => (
        <NetworkContactConnection connection={connection} contactEmail={contactEmail} />
      ))}
    </div>
  );
}

export function NetworkContactConnection(props) {
  const { connection, contactEmail } = props;
  const extraClasses = connection.direction === "sent" ? "text-right" : "";
  let log;
  if (connection.direction === "sent") {
    log = (
      <>
        <span className="text-black font-medium">{connection.connectionEmail}</span>
        {"\u00A0"}
        <span className="capitalize font-[500] w-fit select-none justify-self-center rounded-sm bg-neutral-100 px-2 py-1 text-center text-xs text-black">
          {connection.type}
        </span>
        {/* {"\u00A0"}<span className="text-neutral">{contactEmail}</span>*/}
      </>
    );
  } else {
    log = (
      <>
        {/*<span className="text-neutral">{contactEmail}</span> {"\u00A0"}*/}

        <span className="capitalize font-[500] w-fit select-none justify-self-center rounded-sm bg-neutral-100 px-2 py-1 text-center text-xs text-black">
          {connection.type}
        </span>
        {"\u00A0"}
        <span className="text-black font-medium">{connection.connectionEmail}</span>
      </>
    );
  }
  return (
    <div className={`py-1 round-md ${extraClasses} border-t border-gray-200 `}>
      <div className="text-[14px]">
        <DateChip value={connection.timestamp} /> {log}
      </div>
    </div>
  );
}

const dateTimeFormat = new Intl.DateTimeFormat("en-GB", {
  day: "numeric",
  month: "short",
  year: "numeric",
});
export function DateChip(props) {
  const value = dateTimeFormat.format(new Date(props.value));
  const extraClasses = props.inline ? "w-auto" : "";
  return (
    <span
      className={`text-neutral w-[100px] inline-block font-[500] select-none justify-self-center rounded-sm bg-neutral-100 px-2 py-1 text-center text-xs text-neutral ${extraClasses}`}
    >
      {value}
    </span>
  );
}

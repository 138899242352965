import { useEffect, useState } from "react";

import { useWindowSize } from "@react-hooks-library/core";
import { observer } from "mobx-react";

import { cn } from "@/lib/utils.ts";

import { Icon, Loader, Text } from "@/app/components";
import { EmailMetadata } from "@/app/screens/dashboard/dashboard.types.ts";
import { metricsStore } from "@/app/screens/metrics-extraction";
import { EmailBody, PdfViewer } from "@/app/screens/metrics-extraction/sections";
import { NzrOpportunityEmailQuery } from "@/app/service/emails.gql.ts";
import { useGetFileUrlLazyQuery } from "@/app/service/files.gql.ts";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

export const MetricsContentDisplay = observer(
  ({ email }: { email: NzrOpportunityEmailQuery["nzrOpportunityEmail"] }) => {
    const currentTab = metricsStore.state.tab;
    const setCurrentTab = (tab) => metricsStore.setState({ tab });
    const metadata: EmailMetadata = email?.metadata;
    const attachments = metadata?.process_metrics_extraction_update?.data?.attachments;
    const [presignedUrls, setPresignedUrls] = useState({});

    const [getFileUrl] = useGetFileUrlLazyQuery();
    const fetchMultiplePaths = async (attachments) => {
      if (!attachments) return;
      const results = Object.fromEntries(
        await Promise.all(
          attachments.map(async ({ path_prefix, filename }) => {
            const response = await getFileUrl({ variables: { filePath: `${path_prefix}-original` } });
            return [filename, response.data?.responseGetFilePresignedUrl];
          }),
        ),
      );
      if (Object.keys(results).length === 0) return;
      setPresignedUrls(results);
    };

    const { height } = useWindowSize();
    const size = height - 230;

    useEffect(() => {
      fetchMultiplePaths(attachments).catch();
    }, [attachments]);
    if (!email) return null;

    return (
      <div className="flex size-full flex-1 flex-col pt-4">
        <div className="flex flex-row -space-x-0.5">
          <div
            onClick={() => setCurrentTab("Email Body")}
            className={cn(
              "flex cursor-pointer items-center space-x-2 rounded-t-sm bg-white p-3.5 px-6",
              currentTab !== "Email Body" ? "z-0 bg-[#E4E4E4] text-gray-600" : "z-10",
            )}
          >
            <Icon type={"Mail"} className={"size-4"} />
            <Text text={"Email Body"} color={currentTab !== "Email Body" ? "text-gray-600" : "text-black"} />
          </div>
          {attachments?.map(({ filename }) => {
            return (
              <div
                key={filename}
                onClick={() => setCurrentTab(filename)}
                className={cn(
                  "flex cursor-pointer items-center space-x-2 rounded-t-sm bg-white p-3.5 px-6",
                  currentTab !== filename ? "z-0 bg-[#E4E4E4] text-gray-500" : "z-10",
                )}
              >
                <Icon type={"PDFIcon"} className={"size-4.5"} />
                <Text
                  text={filename}
                  noOfLines={1}
                  color={currentTab !== filename ? "text-gray-600" : "text-black"}
                  className={cn("w-32")}
                />
                {/*<Icon type={"Attachment"} className={"size-4"} />*/}
              </div>
            );
          })}
        </div>
        <div className="flex flex-1 rounded-sm rounded-tl-none  bg-white p-3.5">
          {currentTab === "Email Body" && <EmailBody body={email?.body} />}
          {attachments?.map(({ filename, extension }) => {
            return (
              <div
                key={filename + extension}
                className={cn(currentTab === filename ? "flex w-full flex-1 grow" : "hidden", "h-full")}
                style={{ height: `${size}px` }}
              >
                {presignedUrls[filename] ? (
                  <PdfViewer url={presignedUrls[filename]} />
                ) : (
                  <Loader className="!relative inset-x-0 top-5 flex w-full items-start bg-background pt-0" />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);

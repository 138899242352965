import { NativeModal } from "@/app/components";
import modalStore from "@/app/stores/modal.store";

import { ConfirmModalActionButtons } from "./components";
import { useConfirm } from "./hooks";
import { ConfirmModalProps } from "./types";

export const ConfirmMobile = ({ title, subTitle, icon, handleConfirm, ...props }: ConfirmModalProps) => {
  const { renderContent } = useConfirm({ title, subTitle, icon });

  return (
    <NativeModal
      isOpen
      detent="content-height"
      handleClose={modalStore.close}
      footer={<ConfirmModalActionButtons handleCancel={modalStore.close} handleConfirm={handleConfirm} {...props} />}
    >
      {renderContent}
    </NativeModal>
  );
};

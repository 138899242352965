import { GetFeedbackTemplatesDocument } from "@/gql/graphql";

import { usePaginatedQuery } from "@/app/hooks";
import { DashboardSectionData, NotificationStatus } from "@/app/screens/dashboard/dashboard.types.ts";
import { GetFeedbackTemplatesQuery } from "@/app/service/dashboard.gql.ts";
import { authStore } from "@/app/stores/auth.store.tsx";

export type FeedbackEmailTemplate = NonNullable<GetFeedbackTemplatesQuery["nzrNotifications"]>["edges"][number]["node"];

export const useFeedbackTemplates = (showArchived: boolean): DashboardSectionData<FeedbackEmailTemplate> => {
  const queryVariables = {
    assignee: authStore.user?.id,
    statuses: showArchived
      ? [NotificationStatus.COMPLETED, NotificationStatus.DECLINED]
      : [NotificationStatus.SEEN, NotificationStatus.UNSEEN],
    pageSize: 3,
  };

  const {
    data,
    loading,
    loadMore: fetchMore,
    loadLess,
    loadingLess,
    loadingMore,
  } = usePaginatedQuery({
    query: GetFeedbackTemplatesDocument,
    rootEntity: "nzrNotifications",
    variables: queryVariables,
    options: {
      fetchPolicy: "cache-and-network",
    },
  });

  const loadMore = () => {
    const endCursor = data?.nzrNotifications?.pageInfo.endCursor;

    if (fetchMore) {
      fetchMore(queryVariables, endCursor);
    }
  };

  if (!data?.nzrNotifications) {
    return {
      totalCount: 0,
      items: [],
      loading,
      loadingLess: false,
      loadingMore: false,
      pageInfo: null,
    };
  }

  return {
    totalCount: data.nzrNotifications.totalCount,
    items: data.nzrNotifications.edges.map(({ node }) => node),
    pageInfo: data.nzrNotifications.pageInfo,
    loading: loading && !loadingLess,
    loadingLess,
    loadingMore,
    loadLess,
    loadMore,
  };
};

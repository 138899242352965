import React from "react";

import { cn } from "@/lib/utils.ts";

import { Lottie } from "@/app/components";

/**
 * @deprecated should be removed. Use ProgressSpinner instead
 */
export const Loader = ({ className }: { className?: string }) => (
  <div className={cn("absolute inset-x-0 inset-y-1/2 flex items-center justify-center rounded-lg", className)}>
    <Lottie type={"loading"} className={"size-20 lg:size-30"} />
  </div>
);

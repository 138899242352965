export const colors = {
  primary: {
    DEFAULT: "#E9366B",
    900: "#75113A",
    800: "#8E1B44",
    700: "#A8244E",
    600: "#BF2D57",
    500: "#E9366B",
    400: "#FF4975",
    300: "#FF5E80",
    200: "#FF738B",
    100: "#FFD5E0",
    50: "#ffeef2",
  },
  secondary: {
    DEFAULT: "#EE712A",
    900: "#A32506",
    800: "#B3380F",
    700: "#C54B18",
    600: "#D55E21",
    500: "#EE712A",
    400: "#FF8534",
    300: "#FF9E5F",
    200: "#FFD1B1",
    100: "#FFE8D4",
  },
  yellow: {
    DEFAULT: "#FBAD15",
    900: "#6F500B",
    800: "#93660E",
    700: "#B57B10",
    600: "#D99112",
    500: "#FBAD15",
    400: "#FFC247",
    300: "#FFD680",
    200: "#FFE9B9",
    100: "#FFF5E0",
  },
  green: {
    DEFAULT: "#53C41A",
    900: "#115B0C",
    800: "#227B10",
    700: "#338B12",
    600: "#449B14",
    500: "#53C41A",
    400: "#6ED037",
    300: "#89D65A",
    200: "#A4E77C",
    100: "#C1F8A0",
  },
  red: {
    DEFAULT: "#FF0E00",
    900: "#590B0B",
    800: "#8C1414",
    700: "#B31B1B",
    600: "#DA0F0F",
    500: "#FF0E00",
    400: "#FF625C",
    300: "#FF9B96",
    200: "#FFC8C2",
    100: "#FFF5F4",
  },
  // todo compare with gray and make sure it's consistent
  neutral: {
    DEFAULT: "#7F8084",
    900: "#121315",
    800: "#333436",
    700: "#4C4D50",
    600: "#66676A",
    500: "#7F8084",
    400: "#999A9E",
    300: "#CDCED2",
    200: "#E6E6EA",
    100: "#F6F6FA",
    50: "#FAFAFA",
  },
  grey: "#191A1B",
  background: "rgba(240, 240, 240, 0.7)",
  white: "#FFF",
  black: "#000",
};

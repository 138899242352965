import React from "react";

import { cn } from "@/lib/utils.ts";

import { ChangeIndicator } from "@/app/components/change-indicator/change-indicator.tsx";

import {
  HighlightsCard,
  HighlightsCardEmptyState,
  HighlightsCardFooter,
  HighlightsCardLastUpdated,
  HighlightsCardNumber,
  HighlightsCardTitle,
} from "./highlights-card.tsx";
import { CompanyDataForHighlights } from "./highlights.types.ts";

type RevenueCardProps = {
  company: CompanyDataForHighlights;
};

const useRevenue = (company: CompanyDataForHighlights) => {
  const data = company?.lastMetric?.data ? company.lastMetric?.data : null;
  const yoyGrowth = data?.yoyGrowth?.value && !isNaN(+data?.yoyGrowth?.value) ? data?.yoyGrowth?.value : 0;
  const revenue = data?.ARR?.value && !isNaN(data?.ARR?.value) ? data?.ARR?.value / 1e6 : null;

  return {
    revenue,
    changeValue: yoyGrowth
      ? {
          increase: yoyGrowth > 0 ? yoyGrowth : undefined,
          decrease: yoyGrowth < 0 ? yoyGrowth : undefined,
        }
      : null,
    lastUpdated: data?.ARR?.date, // todo change me
  };
};

const useGrossMargins = (company: CompanyDataForHighlights) => {
  const data = company?.lastMetric?.data ? company.lastMetric?.data : null;
  const grossMargins = data?.grossMargin?.value && !isNaN(data?.grossMargin?.value) ? +data?.grossMargin?.value : null;

  return {
    grossMargins,
    lastUpdated: data?.grossMargin?.date,
  };
};

export const RevenueCard = ({ company }: RevenueCardProps) => {
  const { revenue, changeValue, lastUpdated } = useRevenue(company);
  const { grossMargins } = useGrossMargins(company);

  if (!company.isOpCompany) {
    return null;
  }

  return (
    <HighlightsCard type={"Insight"}>
      {revenue != null || grossMargins != null ? (
        <>
          <div className={"flex h-full justify-between gap-x-3"}>
            {revenue != null && (
              <div className="flex min-w-[150px] flex-col items-center justify-between">
                <HighlightsCardTitle text={"Revenue"} />

                <div className="flex flex-col items-center justify-center ">
                  <HighlightsCardNumber
                    value={revenue}
                    prefix={<span className="mr-2 text-currency">£</span>}
                    suffix="m"
                    options={{ decimals: 2 }}
                    className={cn(!changeValue && "mb-5")}
                  />
                  {changeValue && (
                    <div className="flex gap-1">
                      <ChangeIndicator value={changeValue} options={{ text: { suffix: "%", className: "text-sm" } }} />
                      <span className="text-sm font-semibold text-neutral-800">YoY</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {grossMargins != null && (
              <div className="flex flex-col items-center justify-between text-center">
                <HighlightsCardTitle text={"Gross Margins"} />

                <div className="mb-5 flex items-center">
                  <HighlightsCardNumber value={grossMargins} suffix="%" />
                </div>
              </div>
            )}
          </div>

          <HighlightsCardFooter>
            <HighlightsCardLastUpdated date={lastUpdated} />
          </HighlightsCardFooter>
        </>
      ) : (
        <HighlightsCardEmptyState text={"No Revenue/Gross Margins Data"} />
      )}
    </HighlightsCard>
  );
};

import { getFullName } from "@/lib/utils.ts";

import { Icon, Popover, ResponsiveHorizontalList } from "@/app/components";
import { UserDataForImage, UserImage } from "@/app/screens/dashboard/components/user-image.tsx";
import { SentimentFormData } from "@/app/screens/dashboard/sections/sentiment-form-requests/use-sentiment-form-requests.ts";

enum SentimentFormStatus {
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
}

const UserImageWithStatus = ({ user, state }: { user: UserDataForImage; state?: string }) => {
  return (
    <div className="relative shrink-0">
      <UserImage user={user} />
      <div className="absolute -bottom-1 -left-1.5">
        {state === SentimentFormStatus.COMPLETED && (
          <Icon type="Check" className="size-3.5 rounded-full border border-white bg-green-600 p-0.5 text-white" />
        )}
        {state === SentimentFormStatus.DECLINED && (
          <Icon type="X" className="size-3.5 rounded-full border border-white bg-neutral-400 p-0.5 text-white" />
        )}
      </div>
    </div>
  );
};

export const SentimentParticipants = ({
  participants,
}: {
  participants: SentimentFormData["nzrSentimentFormAssignees"]["nodes"];
}) => {
  console.log(participants);

  return (
    <Popover
      containerClassName="flex-1 overflow-hidden"
      childrenContent={
        <div className="space-y-1 px-0.5">
          {participants.map((assignee) =>
            assignee.member ? (
              <div key={assignee.id} className="flex items-center p-1">
                <UserImageWithStatus user={assignee.member} state={assignee.state} />
                <span className="mx-1">{getFullName(assignee.member?.firstName, assignee.member?.lastName)}</span>
              </div>
            ) : null,
          )}
        </div>
      }
    >
      <ResponsiveHorizontalList
        className="w-full gap-1.5 pb-1 pl-1.5"
        popoverProps={{
          disabled: true,
        }}
        itemProps={{
          className: "shrink-0",
        }}
      >
        {participants.map((assignee) =>
          assignee.member ? (
            <UserImageWithStatus key={assignee.id} user={assignee.member} state={assignee.state} />
          ) : null,
        )}
      </ResponsiveHorizontalList>
    </Popover>
  );
};

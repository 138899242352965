import React, { PropsWithChildren } from "react";

import { cn } from "@/lib/utils.ts";

import { Icon, Popover, Text } from "@/app/components";
import { PageNavSection } from "@/app/components/page-nav/page-nav-section.tsx";
import { normaliseDate } from "@/app/misc/helpers.ts";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import { CompanyDataSource } from "@/app/screens/opportunities/opportunity/use-opportunity.tsx";

type SectionId =
  | "actions"
  | "about"
  | "highlights"
  | "pcg"
  | "founders"
  | "sentiment"
  | "performance"
  | "basecamp"
  | "funding"
  | "news"
  | "attachments"
  | "comments";

type SectionProps = {
  id: SectionId;
  className?: string;
};

export const Section = ({ id, children, className }: PropsWithChildren<SectionProps>) => {
  return (
    <PageNavSection
      id={id}
      className={cn("flex flex-col gap-2 lg:gap-6 lg:px-0", className)}
      anchorClassName={"-top-24"}
    >
      {children}
    </PageNavSection>
  );
};

export type SectionTitleProps = {
  title: string;
  source?: CompanyDataSource;
  enableRefresh?: boolean;
};

export const SectionTitle = ({ title, source, enableRefresh }: SectionTitleProps) => {
  const { refresh, loading } = useOpportunityContext();
  const { viewMode } = useOpportunityContext();

  return (
    <div className="flex items-center space-x-2">
      <h5 className="text-[18px] font-semibold leading-none lg:text-[24px]">{title}</h5>
      {!!source && (
        <Popover
          directionClassName={"border-white"}
          childrenClassName={"ml-4 overflow-visible border-0"}
          childrenContent={
            <>
              <div className={"m-1 flex items-center gap-x-1.5"}>
                <Text
                  text={source.name}
                  type={"message"}
                  weight={"medium"}
                  color={"text-neutral-600"}
                  className={"select-none rounded-sm bg-neutral-100 px-2 py-1.5"}
                />
                <Text
                  text={`last updated on ${normaliseDate(source.lastUpdated)}`}
                  type={"message"}
                  weight={"medium"}
                  color={"text-black"}
                />
              </div>
            </>
          }
          placement={"right"}
        >
          <Icon type={"Info"} className={cn("size-5", viewMode === "contrast" && "invert")} />
        </Popover>
      )}
      {!!enableRefresh && (
        <Popover
          directionClassName={"border-white"}
          childrenClassName={"ml-4 overflow-visible border-0"}
          childrenContent={<Text text="Refresh data" type={"message"} weight={"medium"} color={"text-black"} />}
          placement={"right"}
        >
          <Icon
            className={cn("size-5 hover:text-neutral-600", loading && "pointer-events-none animate-spin")}
            type={"RefreshCw"}
            onClick={refresh}
          />
        </Popover>
      )}
    </div>
  );
};

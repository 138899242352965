import { useMemo, useState } from "react";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { Button, Text } from "@/app/components";
import { normaliseDate } from "@/app/misc/helpers.ts";
import { RequestSentimentModal } from "@/app/screens/modal";
import { opportunityModal } from "@/app/screens/opportunities/actions.ts";
import opportunitiesStore from "@/app/screens/opportunities/opportunities.store.ts";
import { SentimentRadarChart } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-radar-chart.tsx";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import modalStore from "@/app/stores/modal.store";

import { FounderFromTeam, FounderSentimentForms } from "./founders.types.ts";

const useFounderSentiment = (founder: FounderFromTeam) => {
  const sentimentData = founder?.forms ?? [];
  const firstNonNullForm = sentimentData?.find((form) => form?.responses?.length > 0);

  return {
    sentimentData,
    firstNonNullForm,
    isEmpty: !(sentimentData.length > 0 && firstNonNullForm),
  };
};

const AddSentimentButton = ({ founder }: { founder: FounderFromTeam }) => {
  const { isBigTablet, isLaptop } = useBreakpoints();

  const { company } = useOpportunityContext();

  const handleClick = () => {
    if (!company) {
      return;
    }

    modalStore.open(RequestSentimentModal, {
      props: {
        company,
        founderId: founder.id.toString(), // fixme
        type: "founderSentiment",
      },
    });
  };

  return isBigTablet ? (
    <div
      className={cn(
        "mr-8 self-center opacity-0 transition-all duration-300 ease-in-out group-hover:scale-105 group-hover:opacity-100",
      )}
    >
      <Button
        onClick={handleClick}
        text={"Add Sentiment"}
        variant={isLaptop ? "black" : "outline"}
        className={"rounded-sm"}
      />
    </div>
  ) : (
    <Button
      onClick={handleClick}
      text={"Add Sentiment"}
      variant={isLaptop ? "black" : "outline"}
      className={"w-full rounded-tremor-small"}
    />
  );
};

const attrOrder = ["mentality", "execution", "market_fit", "communication", "intellectual_rigour"];

const FounderSentimentChart = ({
  sentimentData,
  firstNonNullForm,
  onClick,
}: {
  sentimentData: FounderSentimentForms;
  firstNonNullForm?: FounderSentimentForms[number];
  onClick: () => void;
}) => {
  const [activeTooltip, setActiveTooltip] = useState(null);

  const { radarChartData, tooltipChartData, ReturnKey, ReturnKeyCompare } = useMemo(() => {
    const radarChartData = attrOrder.map((item) => ({
      label: item.replace("_", " "),
      value: firstNonNullForm?.analytics?.[`${item}_opinion_scale`]?.mean?.toFixed(2),
    }));

    const tooltipForm = sentimentData.find((form) => form.id === activeTooltip);
    const tooltipChartData = activeTooltip
      ? attrOrder.map((item) => ({
          label: item.replace("_", " "),
          value: tooltipForm?.analytics[`${item}_opinion_scale`]?.mean?.toFixed(2),
        }))
      : null;

    const ReturnKey = Object.keys(
      firstNonNullForm?.analytics?.return_multiple_choice || {
        "N/A": true,
      },
    )?.[0];

    const ReturnKeyCompare = Object.keys(
      tooltipForm?.analytics?.return_multiple_choice || {
        "N/A": true,
      },
    )?.[0];

    return { radarChartData, tooltipChartData, ReturnKey, ReturnKeyCompare };
  }, [sentimentData, activeTooltip, firstNonNullForm]);

  return (
    <div className={"flex min-h-[120px] flex-col items-center justify-between"}>
      <div
        className={cn("z-100 h-full cursor-pointer self-center transition-all group-hover:scale-105")}
        onClick={onClick}
      >
        <SentimentRadarChart
          className={"h-[120px]"}
          style={{ width: "180px" }}
          chartFontSize={"0.55rem"}
          activeTooltip={activeTooltip}
          tooltipChartData={tooltipChartData}
          radarChartData={radarChartData}
        />
      </div>
      <Text
        text={normaliseDate(firstNonNullForm?.responses.at(-1).submitted_at) ?? ""}
        type={"small"}
        color={"text-neutral-400"}
        className={"pb-1.5"}
      />
    </div>
  );
};

export const FounderSentiment = ({ founder }: { founder: FounderFromTeam }) => {
  const { sentimentData, firstNonNullForm, isEmpty } = useFounderSentiment(founder);

  const { isBigTablet } = useBreakpoints();

  const handleClick = () => {
    opportunityModal({
      id: `sentiment_details`,
      platform: window.innerWidth <= 976 ? "mobile" : "web",
      action: "Sentiment",
    });

    opportunitiesStore.setModalState("sentiment_details", {
      form: sentimentData?.find((form) => form?.responses?.length > 0),
    });
  };

  if (isEmpty) {
    return <AddSentimentButton founder={founder} />;
  }

  const submittedAt = normaliseDate(firstNonNullForm?.responses.at(-1).submitted_at) ?? "";

  return isBigTablet ? (
    <FounderSentimentChart sentimentData={sentimentData} firstNonNullForm={firstNonNullForm} onClick={handleClick} />
  ) : (
    <Button
      onClick={handleClick}
      iconRight={"MoveUpRight"}
      text={`Sentiment added on ${submittedAt}`}
      variant={"tertiary"}
      className={"w-full overflow-hidden text-ellipsis rounded-tremor-small"}
    />
  );
};

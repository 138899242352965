import React from "react";

import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";
import { useGetCompanyCommentsQuery } from "@/app/service/company-comments.gql.ts";

import { AddCommentInput } from "./add-comment-input.tsx";
import { CommentItem } from "./comment/comment-item.tsx";
import { mapComments } from "./comments.utils.ts";
import { Card } from "@/app/components";

const useComments = () => {
  const { company } = useOpportunityContext();

  const { data: commentsData, loading } = useGetCompanyCommentsQuery({
    variables: {
      companyId: company?.id,
    },
    skip: !company?.id,
    fetchPolicy: "cache-and-network",
  });

  const comments = mapComments(commentsData);

  return { comments, loading };
};

export const Comments = () => {
  const { comments } = useComments();

  return (
    <section id="comments" className="relative">
      <AddCommentInput />
      {comments.length > 0 && (
        <Card className="mt-2 max-h-[80dvh] space-y-2 overflow-auto border-none bg-transparent p-0 lg:space-y-4">
          {comments.map((item) => (
            <CommentItem comment={item} key={item.id} />
          ))}
        </Card>
      )}
    </section>
  );
};

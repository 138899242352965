import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CompanyAttachmentsQueryVariables = Types.Exact<{
  companyId: Types.Scalars["UUID"]["input"];
}>;

export type CompanyAttachmentsQuery = {
  __typename?: "Query";
  lgCompanyFiles?: {
    __typename?: "LgCompanyFilesConnection";
    nodes: Array<{
      __typename?: "LgCompanyFile";
      file?: {
        __typename?: "LgFile";
        id: any;
        name: string;
        path: string;
        status: string;
        createdUtc: any;
        source: Types.LgFileSource;
        metadata?: any | null;
        lgMemberByCreatedBy?: {
          __typename?: "LgMember";
          id: any;
          firstName: string;
          lastName: string;
          username: string;
          avatar?: string | null;
          initials: string;
        } | null;
      } | null;
    }>;
  } | null;
};

export type DeleteCompanyAttachmentMutationVariables = Types.Exact<{
  fileId: Types.Scalars["ID"]["input"];
}>;

export type DeleteCompanyAttachmentMutation = { __typename?: "Mutation"; companyAttachmentDelete?: any | null };

export const CompanyAttachmentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CompanyAttachments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompanyFiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "companyId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "file" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "path" } },
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
                            { kind: "Field", name: { kind: "Name", value: "source" } },
                            { kind: "Field", name: { kind: "Name", value: "metadata" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lgMemberByCreatedBy" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                  { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                  { kind: "Field", name: { kind: "Name", value: "username" } },
                                  { kind: "Field", name: { kind: "Name", value: "avatar" } },
                                  { kind: "Field", name: { kind: "Name", value: "initials" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCompanyAttachmentsQuery__
 *
 * To run a query within a React component, call `useCompanyAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyAttachmentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyAttachmentsQuery, CompanyAttachmentsQueryVariables> &
    ({ variables: CompanyAttachmentsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyAttachmentsQuery, CompanyAttachmentsQueryVariables>(
    CompanyAttachmentsDocument,
    options,
  );
}
export function useCompanyAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyAttachmentsQuery, CompanyAttachmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyAttachmentsQuery, CompanyAttachmentsQueryVariables>(
    CompanyAttachmentsDocument,
    options,
  );
}
export function useCompanyAttachmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyAttachmentsQuery, CompanyAttachmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompanyAttachmentsQuery, CompanyAttachmentsQueryVariables>(
    CompanyAttachmentsDocument,
    options,
  );
}
export type CompanyAttachmentsQueryHookResult = ReturnType<typeof useCompanyAttachmentsQuery>;
export type CompanyAttachmentsLazyQueryHookResult = ReturnType<typeof useCompanyAttachmentsLazyQuery>;
export type CompanyAttachmentsSuspenseQueryHookResult = ReturnType<typeof useCompanyAttachmentsSuspenseQuery>;
export type CompanyAttachmentsQueryResult = Apollo.QueryResult<
  CompanyAttachmentsQuery,
  CompanyAttachmentsQueryVariables
>;
export const DeleteCompanyAttachmentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteCompanyAttachment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "fileId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "companyAttachmentDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "companyAttachmentDeleteInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fileId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "fileId" } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DeleteCompanyAttachmentMutationFn = Apollo.MutationFunction<
  DeleteCompanyAttachmentMutation,
  DeleteCompanyAttachmentMutationVariables
>;

/**
 * __useDeleteCompanyAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyAttachmentMutation, { data, loading, error }] = useDeleteCompanyAttachmentMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteCompanyAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCompanyAttachmentMutation, DeleteCompanyAttachmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCompanyAttachmentMutation, DeleteCompanyAttachmentMutationVariables>(
    DeleteCompanyAttachmentDocument,
    options,
  );
}
export type DeleteCompanyAttachmentMutationHookResult = ReturnType<typeof useDeleteCompanyAttachmentMutation>;
export type DeleteCompanyAttachmentMutationResult = Apollo.MutationResult<DeleteCompanyAttachmentMutation>;
export type DeleteCompanyAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyAttachmentMutation,
  DeleteCompanyAttachmentMutationVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanySentimentAnalytic",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
      "UserRole",
    ],
  },
};
export default result;

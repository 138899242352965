import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetCompanyCommentsQueryVariables = Types.Exact<{
  companyId: Types.Scalars["UUID"]["input"];
}>;

export type GetCompanyCommentsQuery = {
  __typename?: "Query";
  lgCompanyComments?: {
    __typename?: "LgCompanyCommentsConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "LgCompanyComment";
      id: any;
      text: string;
      createdUtc: any;
      lastUpdatedUtc?: any | null;
      metadata?: any | null;
      lgMemberByCreatedBy?: {
        __typename?: "LgMember";
        id: any;
        initials: string;
        firstName: string;
        lastName: string;
        avatar?: string | null;
      } | null;
    }>;
  } | null;
};

export type AddCompanyCommentMutationVariables = Types.Exact<{
  companyId: Types.Scalars["ID"]["input"];
  text: Types.Scalars["String"]["input"];
  metadata?: Types.InputMaybe<Types.CommentMetadataInput>;
}>;

export type AddCompanyCommentMutation = { __typename?: "Mutation"; addCompanyComment?: any | null };

export type UpdateCompanyCommentMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  text: Types.Scalars["String"]["input"];
  metadata?: Types.InputMaybe<Types.CommentMetadataInput>;
}>;

export type UpdateCompanyCommentMutation = { __typename?: "Mutation"; editCompanyComment?: any | null };

export type DeleteCompanyCommentMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type DeleteCompanyCommentMutation = { __typename?: "Mutation"; deleteCompanyComment?: any | null };

export const GetCompanyCommentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompanyComments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompanyComments" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "companyId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "CREATED_UTC_DESC" }] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
                      { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                      { kind: "Field", name: { kind: "Name", value: "metadata" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lgMemberByCreatedBy" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "initials" } },
                            { kind: "Field", name: { kind: "Name", value: "firstName" } },
                            { kind: "Field", name: { kind: "Name", value: "lastName" } },
                            { kind: "Field", name: { kind: "Name", value: "avatar" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompanyCommentsQuery__
 *
 * To run a query within a React component, call `useGetCompanyCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCommentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyCommentsQuery, GetCompanyCommentsQueryVariables> &
    ({ variables: GetCompanyCommentsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyCommentsQuery, GetCompanyCommentsQueryVariables>(
    GetCompanyCommentsDocument,
    options,
  );
}
export function useGetCompanyCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyCommentsQuery, GetCompanyCommentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyCommentsQuery, GetCompanyCommentsQueryVariables>(
    GetCompanyCommentsDocument,
    options,
  );
}
export function useGetCompanyCommentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyCommentsQuery, GetCompanyCommentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCompanyCommentsQuery, GetCompanyCommentsQueryVariables>(
    GetCompanyCommentsDocument,
    options,
  );
}
export type GetCompanyCommentsQueryHookResult = ReturnType<typeof useGetCompanyCommentsQuery>;
export type GetCompanyCommentsLazyQueryHookResult = ReturnType<typeof useGetCompanyCommentsLazyQuery>;
export type GetCompanyCommentsSuspenseQueryHookResult = ReturnType<typeof useGetCompanyCommentsSuspenseQuery>;
export type GetCompanyCommentsQueryResult = Apollo.QueryResult<
  GetCompanyCommentsQuery,
  GetCompanyCommentsQueryVariables
>;
export const AddCompanyCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddCompanyComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "text" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "metadata" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "CommentMetadataInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addCompanyComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "commentInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "companyId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "text" },
                      value: { kind: "Variable", name: { kind: "Name", value: "text" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "metadata" },
                      value: { kind: "Variable", name: { kind: "Name", value: "metadata" } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AddCompanyCommentMutationFn = Apollo.MutationFunction<
  AddCompanyCommentMutation,
  AddCompanyCommentMutationVariables
>;

/**
 * __useAddCompanyCommentMutation__
 *
 * To run a mutation, you first call `useAddCompanyCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyCommentMutation, { data, loading, error }] = useAddCompanyCommentMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      text: // value for 'text'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useAddCompanyCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<AddCompanyCommentMutation, AddCompanyCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCompanyCommentMutation, AddCompanyCommentMutationVariables>(
    AddCompanyCommentDocument,
    options,
  );
}
export type AddCompanyCommentMutationHookResult = ReturnType<typeof useAddCompanyCommentMutation>;
export type AddCompanyCommentMutationResult = Apollo.MutationResult<AddCompanyCommentMutation>;
export type AddCompanyCommentMutationOptions = Apollo.BaseMutationOptions<
  AddCompanyCommentMutation,
  AddCompanyCommentMutationVariables
>;
export const UpdateCompanyCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateCompanyComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "text" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "metadata" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "CommentMetadataInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "editCompanyComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "commentUpdateInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "commentId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "text" },
                      value: { kind: "Variable", name: { kind: "Name", value: "text" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "metadata" },
                      value: { kind: "Variable", name: { kind: "Name", value: "metadata" } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateCompanyCommentMutationFn = Apollo.MutationFunction<
  UpdateCompanyCommentMutation,
  UpdateCompanyCommentMutationVariables
>;

/**
 * __useUpdateCompanyCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyCommentMutation, { data, loading, error }] = useUpdateCompanyCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateCompanyCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyCommentMutation, UpdateCompanyCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanyCommentMutation, UpdateCompanyCommentMutationVariables>(
    UpdateCompanyCommentDocument,
    options,
  );
}
export type UpdateCompanyCommentMutationHookResult = ReturnType<typeof useUpdateCompanyCommentMutation>;
export type UpdateCompanyCommentMutationResult = Apollo.MutationResult<UpdateCompanyCommentMutation>;
export type UpdateCompanyCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyCommentMutation,
  UpdateCompanyCommentMutationVariables
>;
export const DeleteCompanyCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteCompanyComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteCompanyComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "commentDeleteInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "commentId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DeleteCompanyCommentMutationFn = Apollo.MutationFunction<
  DeleteCompanyCommentMutation,
  DeleteCompanyCommentMutationVariables
>;

/**
 * __useDeleteCompanyCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyCommentMutation, { data, loading, error }] = useDeleteCompanyCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCompanyCommentMutation, DeleteCompanyCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCompanyCommentMutation, DeleteCompanyCommentMutationVariables>(
    DeleteCompanyCommentDocument,
    options,
  );
}
export type DeleteCompanyCommentMutationHookResult = ReturnType<typeof useDeleteCompanyCommentMutation>;
export type DeleteCompanyCommentMutationResult = Apollo.MutationResult<DeleteCompanyCommentMutation>;
export type DeleteCompanyCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyCommentMutation,
  DeleteCompanyCommentMutationVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanySentimentAnalytic",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
      "UserRole",
    ],
  },
};
export default result;

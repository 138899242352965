import { useState } from "react";

import { Icon, NativeModal } from "@/app/components";
import modalStore from "@/app/stores/modal.store.tsx";

export const SelectMobile = ({ options, handleSelect, selectedOption, ...props }) => {
  const [selectionOption, setSelectedOption] = useState(selectedOption.label);
  return (
    <NativeModal isOpen detent="content-height" handleClose={modalStore.close} footer={null} {...props}>
      {options.map(({ value, label }) => (
        <p
          key={label}
          onClick={() => {
            setSelectedOption(label);
            handleSelect({ value, label });
          }}
          className={`flex cursor-pointer justify-between border-b border-neutral-100 p-4 hover:bg-gray-100`}
        >
          {label}
          {selectionOption === label && (
            <Icon type={"Check"} className={"size-5 rounded-full bg-primary p-0.5 text-white"} />
          )}
        </p>
      ))}
    </NativeModal>
  );
};

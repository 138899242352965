import citiesJson from "./cities.json";
import continentsJson from "./continents.json";
import countriesJson from "./countries.json";

export const countriesMap = countriesJson;
export const citiesList = Object.keys(citiesJson).map((item) => ({
  value: item,
  label: item,
}));
export const countriesList = Object.keys(countriesJson).map((item) => ({
  value: item,
  label: item,
}));
export const continents = continentsJson.map((item) => ({
  value: item,
  label: item,
}));
export const signals = [
  {
    label: "Just Raised",
    value: "just_raised",
    description: "Raised a round in the last 14 months!",
  },
  {
    label: "Might Raise",
    value: "might_raise",
    description: "Didn't raise a round in over 18 months, might raise soon!",
  },

  {
    label: "Manually Added",
    value: "manual_web",
    description: "Manually added by someone!",
  },
];
export const targetFund = [
  {
    value: "LocalGlobe",
    label: "LocalGlobe",
  },
  {
    value: "Latitude",
    label: "Latitude",
  },
  {
    value: "Solar",
    label: "Solar",
  },
];

export const investmentType = [
  {
    value: "Core",
    label: "Core",
  },
  {
    value: "Strategic",
    label: "Strategic",
  },
];

export const stages = [
  {
    value: "NOT YET RAISED",
    label: "Not Yet Raised",
  },
  {
    value: "ANGEL",
    label: "Angel",
  },
  {
    value: "PRE-SEED",
    label: "Pre-Seed",
  },
  {
    value: "SEED",
    label: "Seed",
  },
  {
    value: "SERIES A",
    label: "Series A",
  },
  {
    value: "SERIES B",
    label: "Series B",
  },
  {
    value: "SERIES C",
    label: "Series C",
  },
  {
    value: "SERIES D",
    label: "Series D",
  },
  {
    value: "SERIES E",
    label: "Series E",
  },
  {
    value: "SERIES F",
    label: "Series F",
  },
  {
    value: "SERIES G",
    label: "Series G",
  },
  {
    value: "SERIES H",
    label: "Series H",
  },
  {
    value: "SERIES I",
    label: "Series I",
  },
];

export const pipelines = [
  {
    value: "LocalGlobe",
    label: "LocalGlobe",
  },
  {
    value: "Latitude",
    label: "Latitude",
  },
  {
    value: "Solar",
    label: "Solar",
  },
];

export const pipelineTrelloColumns = {
  LocalGlobe: [
    "561fe5725d03faeba0a20b5e",
    "5626767dccf2aa74870b6bab",
    "59372e2427706549525b33d7",
    "56033a23d323b95c008363ff",
    "55f74f188189b52465970cb2",
    "558c7d47afd9cbf4ccc4c969",
  ],
  Latitude: [
    "5bda283580b2298c3e791071",
    "5bdae47c6c0728111d122923",
    "5bda298f418a8f6cd8e10d12",
    "5f59f2a2335784813eefa3e2",
    "5e85c8041084764716df22bf",
    "5e29766fccf0f0041c7bb586",
    "5bda26969461ac8bbed1d0f8",
  ],
  Solar: [
    "619b69465c658d509f7de1b0",
    "63764680c2233703ffa09dd2",
    "6440ff7c840f4de7d460106f",
    "63c000b83a58ae0112202713",
    "61559487ab1c8d67d4e06a6d",
    "62c6c4f042096b8c33418d79",
    "637646faa0bfe00221715461",
  ],
};

export const trelloColumnsList = [
  // {
  //   value: "55f74f188189b52465970cb2",
  //   label: "LG Fund Raising/Inbound (now)",
  // },
  {
    value: "558c7d47afd9cbf4ccc4c969",
    label: "LG Test Chemistry & EV Filter",
  },
  {
    value: "59372e2427706549525b33d7",
    label: "LG Developing Conviction",
  },
  {
    value: "5626767dccf2aa74870b6bab",
    label: "LG Making Investment Offer",
  },
  {
    value: "561fe5725d03faeba0a20b5e",
    label: "LG Legals (next 4 weeks)",
  },
  // {
  //   value: "64b51cdcb608fceb003136bc",
  //   label: "M&A",
  // },
  // {
  //   value: "63720f01997afa008b7eaaa4",
  //   label: "LG Liquidation",
  // },
  // {
  //   value: "63720f0fc63a1025314a0661",
  //   label: "PCW - Legals",
  // },
  // {
  //   value: "5afcb48ebb3bb2acf2db49ec",
  //   label: "LG7 milestones",
  // },
  {
    value: "5bda26969461ac8bbed1d0f8",
    label: "LT - Test Chemistry/10x Filter",
  },
  {
    value: "5bda298f418a8f6cd8e10d12",
    label: "LT Developing Conviction",
  },
  {
    value: "5bdae47c6c0728111d122923",
    label: "LT - Making Investment Offer",
  },
  {
    value: "5d4bfb8eb30b468aa3ada3ff",
    label: "LT Legals",
  },
  // {
  //   value: "59cadbff9d1b7a05ef9bd124",
  //   label: "LT - LG Breakouts (raise in <12mo)",
  // },
  // {
  //   value: "556104a770ab0acf382d095d",
  //   label: "LG Screened Intros",
  // },
  // {
  //   value: "56033a23d323b95c008363ff",
  //   label: "LG Upcoming Session",
  // },
  // {
  //   value: "61a4bc1a2de048874a00dd6f",
  //   label: "Cap Table, No Capital",
  // },
  // {
  //   value: "560338a7c58552e5abe820b3",
  //   label: "LG8 Milestones",
  // },
  // {
  //   value: "5d2e1a930fe6b61066c4b0c7",
  //   label: "LGX Milestones",
  // },
  // {
  //   value: "605865b8ed35f80dc6874d65",
  //   label: "LG11 Milestones",
  // },
  // {
  //   value: "632dc2ff62a0260393ab51cb",
  //   label: "LG12 Milestones",
  // },
  // {
  //   value: "609c581acfafd9811980da2a",
  //   label: "LG5 Monitoring",
  // },
  // {
  //   value: "596c8c35fb8588aa0e258692",
  //   label: "LG7 monitoring",
  // },
  // {
  //   value: "5afcb3f801acdb6ea6b434bc",
  //   label: "LG8 monitoring",
  // },
  // {
  //   value: "5d2c4111ccb8d3307b344dd8",
  //   label: "LGX monitoring",
  // },
  // {
  //   value: "605865cde2d0c6160ac5aa6f",
  //   label: "LG11 Monitoring",
  // },
  // {
  //   value: "61f1d7e583cab686dd9be321",
  //   label: "LT - LG Breakouts (raise in >12 Months)",
  // },
  // {
  //   value: "5e85c8041084764716df22bf",
  //   label: "LT Upcoming Session",
  // },
  // {
  //   value: "5bda26144b57917f8acd0a1f",
  //   label: "LT1 Quarterly",
  // },
  // {
  //   value: "637b53c9e96955006ea24841",
  //   label: "LT3 Quarterly",
  // },
  // {
  //   value: "6064eae1150608529f7563c0",
  //   label: "Public",
  // },
  {
    value: "637646faa0bfe00221715461",
    label: "Solar Pipeline - Chemistry",
  },
  {
    value: "62c6c4f042096b8c33418d79",
    label: "Solar Prospects",
  },
  {
    value: "61559487ab1c8d67d4e06a6d",
    label: "Solar Deep Dives",
  },
  {
    value: "63764680c2233703ffa09dd2",
    label: "Solar Making Investment Offer",
  },
  {
    value: "619b69465c658d509f7de1b0",
    label: "Solar/X Legals",
  },
  // {
  //   value: "60408af244515423ec83760d",
  //   label: "Solar - LG/LT Breakouts",
  // },
  // {
  //   value: "5fc1fb058af24e65dccae7a2",
  //   label: "Solar - NPA Breakouts",
  // },
  // {
  //   value: "6331a69198be89020b90fe19",
  //   label: "Basecamp Screened Intro",
  // },
  // {
  //   value: "647737808ed522009d9881db",
  //   label: "Basecamp Fundraising",
  // },
  // {
  //   value: "6200ecc97501077736228b4a",
  //   label: "Basecamp Legals",
  // },
  // {
  //   value: "624226092d02161be5c19e10",
  //   label: "Basecamp Israel",
  // },
  // {
  //   value: "63590618424bf303824e0ea4",
  //   label: "Basecamp Global",
  // },
  // {
  //   value: "624226546236a5825254300b",
  //   label: "Basecamp India & APAC",
  // },
  // {
  //   value: "6331a7403a732400cd0571eb",
  //   label: "Basecamp Track",
  // },
  // {
  //   value: "56033a8db8bdf17fed345c51",
  //   label: "Exits",
  // },
  // {
  //   value: "59542d321266138ecaf9eb9f",
  //   label: "Missed [!E]",
  // },
  // {
  //   value: "5561070fb5b3702be10a0806",
  //   label: "LG5 Track",
  // },
  // {
  //   value: "5e29766fccf0f0041c7bb586",
  //   label: "LT Weekly",
  // },
  // {
  //   value: "5e56f17fbcd8234caf0f393e",
  //   label: "LT Fund Raising/Inbound",
  // },
  // {
  //   value: "60ca9d0648de074531414ad9",
  //   label: "LT2 Quarterly",
  // },
  // {
  //   value: "6064eb73422f0e482c84b758",
  //   label: "Solar - Pre-IPO",
  // },
  // {
  //   value: "63c000b83a58ae0112202713",
  //   label: "Solar Upcoming Session",
  // },
  // {
  //   value: "6425513e305ce068dbc8feb9",
  //   label: "Solar Ops",
  // },
  // {
  //   value: "639208dfafebe4040a152b5f",
  //   label: "Solar Quarterly",
  // },
  // {
  //   value: "609c561ff456d665dfe81f2a",
  //   label: "Solar - Basecamp Breakouts",
  // },
  // {
  //   value: "609c56218633aa87b758d848",
  //   label: "Solar - Prepared Mind",
  // },
  // {
  //   value: "624226da9976a27358ba8faf",
  //   label: "Basecamp Developing Conviction",
  // },
  // {
  //   value: "63be9178a5e000004513c648",
  //   label: "Basecamp Commitments",
  // },
  // {
  //   value: "6242250c9eb0b14cf9c62b90",
  //   label: "Basecamp Europe",
  // },
  // {
  //   value: "624225a874b0b0188bc0f916",
  //   label: "Basecamp US",
  // },
  // {
  //   value: "633fefe835e93101b6de6cd6",
  //   label: "Basecamp Africa",
  // },
  // {
  //   value: "635905fbbdf0e002d8dfd544",
  //   label: "Basecamp Community",
  // },
  // {
  //   value: "566ecdbfe5424d4a547eaa6d",
  //   label: "Write Off",
  // },
  // {
  //   value: "6383d30ea10e41067e5195dd",
  //   label: "Stealth",
  // },
  // {
  //   value: "6440ff7c840f4de7d460106f",
  //   label: "Solar Fundraising",
  // },
];

export const trelloColumns = {
  "LG Fund Raising/Inbound (now)": "55f74f188189b52465970cb2",
  "LG Developing Conviction": "59372e2427706549525b33d7",
  "LG Making Investment Offer": "5626767dccf2aa74870b6bab",
  "LG Legals (next 4 weeks)": "561fe5725d03faeba0a20b5e",
  "M&A": "64b51cdcb608fceb003136bc",
  "LG Liquidation": "63720f01997afa008b7eaaa4",
  "PCW - Legals": "63720f0fc63a1025314a0661",
  "LG7 milestones": "5afcb48ebb3bb2acf2db49ec",
  "LT - Test Chemistry/10x Filter": "5bda26969461ac8bbed1d0f8",
  "LT - LG Breakouts (raise in <12mo)": "59cadbff9d1b7a05ef9bd124",
  "LG Screened Intros": "556104a770ab0acf382d095d",
  "LG Test Chemistry & EV Filter": "558c7d47afd9cbf4ccc4c969",
  "LG Upcoming Session": "56033a23d323b95c008363ff",
  "Cap Table, No Capital": "61a4bc1a2de048874a00dd6f",
  "LG8 Milestones": "560338a7c58552e5abe820b3",
  "LGX Milestones": "5d2e1a930fe6b61066c4b0c7",
  "LG11 Milestones": "605865b8ed35f80dc6874d65",
  "LG12 Milestones": "632dc2ff62a0260393ab51cb",
  "LG5 Monitoring": "609c581acfafd9811980da2a",
  "LG7 monitoring": "596c8c35fb8588aa0e258692",
  "LG8 monitoring": "5afcb3f801acdb6ea6b434bc",
  "LGX monitoring": "5d2c4111ccb8d3307b344dd8",
  "LG11 Monitoring": "605865cde2d0c6160ac5aa6f",
  "LT - LG Breakouts (raise in >12 Months)": "61f1d7e583cab686dd9be321",
  "LT Upcoming Session": "5e85c8041084764716df22bf",
  "LT Developing Conviction": "5bda298f418a8f6cd8e10d12",
  "LT1 Quarterly": "5bda26144b57917f8acd0a1f",
  "LT3 Quarterly": "637b53c9e96955006ea24841",
  Public: "6064eae1150608529f7563c0",
  "Solar Deep Dives": "61559487ab1c8d67d4e06a6d",
  "Solar Making Investment Offer": "63764680c2233703ffa09dd2",
  "Solar/X Legals": "619b69465c658d509f7de1b0",
  "Solar - LG/LT Breakouts": "60408af244515423ec83760d",
  "Solar - NPA Breakouts": "5fc1fb058af24e65dccae7a2",
  "Basecamp Screened Intro": "6331a69198be89020b90fe19",
  "Basecamp Fundraising": "647737808ed522009d9881db",
  "Basecamp Legals": "6200ecc97501077736228b4a",
  "Basecamp Israel": "624226092d02161be5c19e10",
  "Basecamp Global": "63590618424bf303824e0ea4",
  "Basecamp India & APAC": "624226546236a5825254300b",
  "Basecamp Track": "6331a7403a732400cd0571eb",
  Exits: "56033a8db8bdf17fed345c51",
  "Missed [!E]": "59542d321266138ecaf9eb9f",
  "LG5 Track": "5561070fb5b3702be10a0806",
  "LT Weekly": "5e29766fccf0f0041c7bb586",
  "LT Fund Raising/Inbound": "5e56f17fbcd8234caf0f393e",
  "LT - Making Investment Offer": "5bdae47c6c0728111d122923",
  "LT Legals": "5d4bfb8eb30b468aa3ada3ff",
  "LT2 Quarterly": "60ca9d0648de074531414ad9",
  "Solar - Pre-IPO": "6064eb73422f0e482c84b758",
  "Solar Pipeline - Chemistry": "637646faa0bfe00221715461",
  "Solar Upcoming Session": "63c000b83a58ae0112202713",
  "Solar Prospects": "62c6c4f042096b8c33418d79",
  "Solar Ops": "6425513e305ce068dbc8feb9",
  "Solar Quarterly": "639208dfafebe4040a152b5f",
  "Solar - Basecamp Breakouts": "609c561ff456d665dfe81f2a",
  "Solar - Prepared Mind": "609c56218633aa87b758d848",
  "Basecamp Developing Conviction": "624226da9976a27358ba8faf",
  "Basecamp Commitments": "63be9178a5e000004513c648",
  "Basecamp Europe": "6242250c9eb0b14cf9c62b90",
  "Basecamp US": "624225a874b0b0188bc0f916",
  "Basecamp Africa": "633fefe835e93101b6de6cd6",
  "Basecamp Community": "635905fbbdf0e002d8dfd544",
  "Write Off": "566ecdbfe5424d4a547eaa6d",
  Stealth: "6383d30ea10e41067e5195dd",
  "Solar Fundraising": "6440ff7c840f4de7d460106f",
};

export const basecampFunds = [
  {
    id: "3fc7360c-b3f7-428e-9310-931139142266",
    name: "Emerge Education",
  },
  {
    id: "23a5f3e7-ed7b-4f42-9d3f-1bf23fee1959",
    name: "The Todd & Rahul Angel Fund",
  },
  {
    id: "dc99bb28-4011-421f-bd1e-5e677317fb8f",
    name: "Seedcamp",
  },
  {
    id: "0a8f57f4-0751-4e62-bf18-f86a8ba304fc",
    name: "Future Positive Capital",
  },
  {
    id: "53d84b6b-c900-42d0-9fd6-df7286b0dc65",
    name: "lool ventures",
  },
  {
    id: "890032df-6e3b-4aa4-a9a2-e1ba7ef95b5e",
    name: "AirTree Ventures",
  },
  {
    id: "be237214-f7b6-450b-bb30-36b5e1a17ffa",
    name: "TenOneTen Ventures",
  },
  {
    id: "cc454ff8-cdf6-4917-886d-fa0423ced1d4",
    name: "India Quotient",
  },
  {
    id: "135f9ef7-ba69-44cc-9d6d-825d1deb9fce",
    name: "Hardware club",
  },
  {
    id: "84d98139-0519-4de5-a3bc-0fd0f3ef104a",
    name: "LDV Capital",
  },
  {
    id: "cf794332-7495-4d3e-9209-2b839b648545",
    name: "Fly Ventures",
  },
  {
    id: "e887dab4-982a-4e4a-a5ce-2c5cb3f09700",
    name: "UCL Technology Fund",
  },
  {
    id: "5276bb0d-6fa0-49b9-a3a6-5b1abe52284b",
    name: "firstminute capital",
  },
  {
    id: "6a48b2c4-5f4f-4d1a-8c55-42b86d6c4974",
    name: "Chalfen Ventures",
  },
  {
    id: "8671d7dd-4f6d-4fe6-9380-a6862604d63b",
    name: "Hetz Ventures",
  },
  {
    id: "5cd46876-b848-4887-971a-18307bc1891e",
    name: "Fabric Ventures",
  },
  {
    id: "a6b5bc7d-df00-4b36-aebc-d1083ba3ff8b",
    name: "Antler",
  },
  {
    id: "471ca992-5fe0-4c32-8db6-32ce0bfe5b73",
    name: "Shrug Capital",
  },
  {
    id: "f9bbe008-6890-4d18-9f2d-12997a58c796",
    name: "Public.io",
  },
  {
    id: "859ff95d-a5df-4787-92f3-d99c2e31f279",
    name: "Entrepreneur First",
  },
  {
    id: "a5712cf8-519f-4332-8ecd-75242f94af67",
    name: "Moonfire Ventures",
  },
  {
    id: "b674c55e-43c1-4efe-8c2c-79cc86660ffa",
    name: "Enza Capital",
  },
  {
    id: "07db0f5e-2fae-49cf-b94f-b227ec4e4a7e",
    name: "Impact X Capital",
  },
  {
    id: "c4f37968-9b4a-401d-8918-d86329111933",
    name: "Magic Fund",
  },
  {
    id: "75195f4b-4ea6-4906-9789-7c29e5a5f9a9",
    name: "Avid Ventures",
  },
  {
    id: "3cbe32a5-ec40-466d-bf0f-41bef26111f4",
    name: "8-Bit Capital",
  },
  {
    id: "4a120a83-59be-4151-bdcc-5ec110a71ef1",
    name: "Otherwise Fund",
  },
  {
    id: "a68f90b1-cfcf-4ef6-a6aa-e3bdf6378b39",
    name: "Future Africa",
  },
  {
    id: "e06f3cc3-1f68-4575-95bc-0b3159e9ce4d",
    name: "First Spark Ventures",
  },
  {
    id: "26f19030-5097-40f5-88ef-e34bc5b42e91",
    name: "Adjacent",
  },
  {
    id: "72a6dcdf-8aa4-4abd-98c8-6f485feb6368",
    name: "Kindred Ventures",
  },
  {
    id: "34e09ba4-fd51-492a-abb7-27c40eb142b9",
    name: "Jigsaw VC",
  },
  {
    id: "41130b53-2607-48ac-ba5e-86c1616d4976",
    name: "Form Capital",
  },
  {
    id: "13ac3ed5-e345-4515-9afc-50666cd9ccf6",
    name: "Patron",
  },
  {
    id: "f3a6094d-20f5-4cc7-99b8-3e24c5320744",
    name: "Offline Ventures",
  },
  {
    id: "6ea4dd56-5a57-4e4f-a48e-103b94fd3615",
    name: "Ethereal Ventures",
  },
  {
    id: "98d314ef-c089-4af8-a2c2-f105b2754c9d",
    name: "Banana Capital",
  },
  {
    id: "842ce694-648a-449f-b559-27ead9db6660",
    name: "Outsized Ventures",
  },
  {
    id: "834f964f-50de-4b5e-8811-ff9f67d4f88c",
    name: "Cocoa VC",
  },
  {
    id: "eca34295-5b40-4c5f-abc8-93e46747a3d9",
    name: "Possible Ventures",
  },
  {
    id: "ecbf5d1f-0888-4df8-880c-bacc15d3124c",
    name: "Side Stage Ventures",
  },
  {
    id: "c12993f7-6f1f-4093-9490-393018a77797",
    name: "Plural",
  },
  {
    id: "1b207557-cdfe-4b8b-b105-f5d7ec7940b0",
    name: "Passion Capital",
  },
  {
    id: "78dbb366-7b72-49f0-9a71-97b6ae5f87b8",
    name: "JamJar Investments",
  },
  {
    id: "b4acdb92-3f9e-4166-aa64-2aa04870dcc4",
    name: "Connect Ventures",
  },
  {
    id: "6347a572-4ada-4e02-8ce9-066f6103c1d5",
    name: "500 Global",
  },
  {
    id: "2da4c14a-b4fc-4cb5-beb8-a61f8da751c6",
    name: "Tiny VC",
  },
  {
    id: "0b9fafdd-7700-4b75-805d-4528d45287a5",
    name: "Remote First Capital",
  },
  {
    id: "8fc2280c-cae4-4c8c-8d51-513f131675b9",
    name: "Air Street Capital",
  },
];

export const pcgFunds = [
  {
    id: 2,
    name: "LG12",
    parent: "LocalGlobe",
  },
  {
    id: 11,
    name: "LG11",
    parent: "LocalGlobe",
  },
  {
    id: 3,
    name: "LGX",
    parent: "LocalGlobe",
  },
  {
    id: 12,
    name: "LGO",
    parent: "LocalGlobe",
  },
  {
    id: 4,
    name: "LG8",
    parent: "LocalGlobe",
  },
  {
    id: 5,
    name: "LG7",
    parent: "LocalGlobe",
  },
  {
    id: 1,
    name: "LG5",
    parent: "LocalGlobe",
  },
  {
    id: 14,
    name: "LG4",
    parent: "LocalGlobe",
  },
  {
    id: 21,
    name: "LT3",
    parent: "Latitude",
  },
  {
    id: 13,
    name: "LT2",
    parent: "Latitude",
  },
  {
    id: 8,
    name: "LT1",
    parent: "Latitude",
  },
  {
    id: 23,
    name: "LG1",
    parent: "LocalGlobe",
  },
  {
    id: 15,
    name: "LG2",
    parent: "LocalGlobe",
  },
  {
    id: 20,
    name: "LG6",
    parent: "LocalGlobe",
  },

  {
    id: 9,
    name: "LG9",
    parent: "LocalGlobe",
  },
  {
    id: 25,
    name: "LG9a",
    parent: "LocalGlobe",
  },
  {
    id: 19,
    name: "LG9B",
    parent: "LocalGlobe",
  },
  {
    id: 24,
    name: "LT-Raisin",
    parent: "Latitude",
  },
  {
    id: 10,
    name: "LT-SC",
    parent: "Latitude",
  },
  {
    id: 22,
    name: "LT-SC via LT-Tessian",
    parent: "Latitude",
  },
  {
    id: 28,
    name: "LT-SCviaLT-Tessian",
    parent: "Latitude",
  },
  {
    id: 7,
    name: "LT-SC via LTX",
    parent: "Latitude",
  },
  {
    id: 27,
    name: "LT-SCviaLTX",
    parent: "Latitude",
  },
  {
    id: 17,
    name: "LT-TES",
    parent: "Latitude",
  },
  {
    id: 6,
    name: "LT-X",
    parent: "Latitude",
  },
  {
    id: 1002,
    name: "SF1",
    parent: "Solar",
  },
  {
    id: 29,
    name: "SolarFund1",
    parent: "Solar",
  },
  {
    id: 16,
    name: "STLP",
    parent: "Solar",
  },
  {
    id: 26,
    name: "SX-Co",
    parent: "Solar",
  },
  {
    id: 18,
    name: "TAG",
    parent: "Solar",
  },
];

export const restrictedFunds = [
  {
    value: "LG12",
    label: "LocalGlobe 12",
  },
  {
    value: "LG11",
    label: "LocalGlobe 11",
  },
  {
    value: "LGX",
    label: "LocalGlobe 10",
  },
  {
    value: "LG8",
    label: "LocalGlobe 8",
  },
  {
    value: "LG7",
    label: "LocalGlobe 7",
  },
  {
    value: "LT3",
    label: "Latitude 3",
  },
  {
    value: "LT2",
    label: "Latitude 2",
  },
  {
    value: "LT1",
    label: "Latitude 1",
  },
  {
    value: "SF1",
    label: "Solar Fund 1",
  },
];

export const dateList = [
  { id: "1", label: "Last 7 Days", value: 7 },
  { id: "2", label: "Last Month", value: 30 },
  { id: "3", label: "Last Quarter", value: 120 },
  { id: "4", label: "Last Year", value: 365 },
];

export const basecampInvestorCount = [
  { id: "1", label: "at least 1", value: 1 },
  { id: "2", label: "at least 2", value: 2 },
  { id: "3", label: "at least 3", value: 3 },
  { id: "4", label: "more than 3", value: 4 },
];

export const filterNoSort = [
  "Target Fund",
  "Last Round",
  "Pipeline",
  "Phoenix Court Group Funds",
  "Prospect Stage",
  "Top Investors",
];

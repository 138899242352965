import { useEffect, useLayoutEffect } from "react";

import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router";

import { Button, Icon, Text } from "@/app/components";
import { metricsStore } from "@/app/screens/metrics-extraction";
import { CompanySelectModal } from "@/app/screens/metrics-extraction/sections";
import { useCompanyDetailsMetricsQuery } from "@/app/service/metrics.gql.ts";
import modalStore from "@/app/stores/modal.store.tsx";

export const useMetricsHeader = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const selectedCompanyId = metricsStore.state?.selectedCompanyId || state?.companyId || "new";

  const { data, loading, error } = useCompanyDetailsMetricsQuery({
    variables: { id: selectedCompanyId },
    skip: selectedCompanyId === "new",
    fetchPolicy: "network-only",
  });

  const { name, image } = data?.lgCompany || {};

  useLayoutEffect(() => {
    metricsStore.setState({ selectedCompanyId });
  }, []);

  useEffect(() => {
    if (error) {
      toast.custom(() => (
        <div className={"flex h-10 w-fit items-center gap-x-3 rounded-md bg-white px-3 py-2"}>
          <Icon type={"X"} width={16} height={16} className="rounded-full bg-red p-0.5 text-white" />
          <Text text={"We weren’t able to find a direct match in our database."} />
          <Button text={"Link company"} size={"sm"} onClick={() => modalStore.open(CompanySelectModal)} />
        </div>
      ));
    }
  }, [error]);

  return { loading, name, image, error, navigate, selectedCompanyId };
};
